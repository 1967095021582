import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Spin } from "antd";

import {
  renderLabel,
  genRenderItem,
  exportTemplate
} from "../../../libs/echar";

import Chart, { EChartsOption } from "../../../components/Chart";

import {
  createK,
  createQP,
  calcYAxis,
  precision,
  findIntersectionPoint,
  createAuxiliaryData,
  createQH2Data,
  createQEFF2Data,
  createQNPSH2Data,
  createDiameterData,
  createSpeedData,
  createFrequencyData,
  convertEFF1ToEFF2,
  powerFormula,
  parseSpuPointsDataset,
  findClosestNumber,
  getNpshrByData,
  generateCatmullRomPoints
} from "../../../utils";

import useSpuPoints, {
  SpuPointsDataset,
  PerformanceFormField,
  SpuPointsInfo
} from "../../../stores/spuPoints";

import { DataPoint } from "regression";
import { LineSeriesOption } from "echarts/charts";
import { useTranslation } from "react-i18next";
import { EChartsType } from "echarts/core";

import useSpuPdfParam from "../../../stores/spuPdfParam";

import './PerformanceChart.css';

// 性能曲线图 [有时候图的 tooltip 并不是不对, 只是QH 和 QH2的 点位并不能一一对上]

interface PerformanceChartProps {
  renderType?: any;
  name: string; 
  info: SpuPointsInfo;
  dataset: SpuPointsDataset;
  performance: PerformanceFormField;
}

const PerformanceChart = ({
  renderType = 'default', // 类型 table: 表格里面 popover: 弹出层里面 default: 默认
  name, // 名字
  info, // 频率那些 基础数据
  dataset, // 点 基础数据
  performance // 输入数据, q h sg
}: PerformanceChartProps) => {
  const { t } = useTranslation();
  const [drawLoading, setDrawLoading] = useState(false);

  const {
    performanceForm,
    setPerformanceValue,
    setPerformanceResult
  } = useSpuPoints();

  const [
    searchParams,
    setSearchParams
  ] = useSearchParams();

  let initialOption: EChartsOption = {};

  if (renderType === 'default') {
    initialOption = {
      tooltip: { // 提示框
        trigger: "axis", // 坐标轴触发
        axisPointer: { // 坐标轴指示器
          type: "cross", // 十字准星
        }
        // formatter: (a: any) => { // 自定义tooltip
        //   console.log(a);
  
        //   return 'a';
        // }
      },
      toolbox: { // 工具栏
        feature: { // 各工具配置项
          saveAsImage: {
            name: name,
            pixelRatio: 2 // 精度
          }
        }
      },
      grid: [{ bottom: "40%" }, { top: "70%" }],
      xAxis: [
        // Q1
        {
          name: "m³/h",
          type: "value",
          splitNumber: 10,
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            hideOverlap: true,
            formatter: function (value: any, index: any) {
              if (index % 2 === 0) {
                return value;
              }

              return '';
            }
          }
        },
        // Q2
        {
          name: "m³/h",
          type: "value",
          gridIndex: 1,
          splitNumber: 10,
          axisLabel: {
            showMinLabel: true,
            showMaxLabel: true,
            hideOverlap: true,
            formatter: function (value: any, index: any) {
              if (index % 2 === 0) {
                return value;
              }

              return '';
            }
          }
        }
      ]
    };
  }

  if (renderType === 'popover') {
    initialOption = {
      tooltip: { // 提示框
        trigger: "axis", // 坐标轴触发
        axisPointer: { // 坐标轴指示器
          type: "cross", // 十字准星
        }
        // formatter: (a: any) => { // 自定义tooltip
        //   console.log(a);
  
        //   return 'a';
        // }
      },
      grid: [{ bottom: "40%" }, { top: "70%" }],
      xAxis: [
        // Q1
        {
          name: "m³/h",
          type: "value"
        },
        // Q2
        {
          name: "m³/h",
          type: "value",
          gridIndex: 1
        }
      ]
    };
  }

  if (renderType === 'table') {
    initialOption = {
      xAxis: [
        {
          name: "m³/h",
          type: "value",
          silent: true,
          axisLabel: {
            show: false
          }
        }
      ]
    };
  }


  const [option, setOption] = useState(initialOption);

  useEffect(() => {
    // 对原始数据进行线性回归
    const {
      QH,
      QP,
      QEFF,
      QNPSH
    } = parseSpuPointsDataset(dataset);

    const {
      quantity,
      head,
      sg,
      type // 选择是公式1 还是 公式2
    } = performance;

    const QHDummyPoints = QH.dummyPoints;
    const QEFFDummyPoints = QEFF.dummyPoints;

    // 找到共有区间
    let [hMax, hInterval] = calcYAxis(
      QHDummyPoints.map((p) => p[1]),
      40,
      1
    );

    let [pMax, pInterval] = calcYAxis(
      QP.map((p) => p[1]),
      20,
      1
    );

    let QEFFIndex = 0;

    for (let i in QEFFDummyPoints) {
      const v = QEFFDummyPoints[i];

      const y = v[1];

      if (y > 0) {
        QEFFIndex = Number(i);

        break;
      }
    }

    // const QHDash = QHDummyPoints.filter(v => v[0] < 3);
    // const QHLine = QHDummyPoints.filter(v => v[0] >= 3);

    // 创建 PerformanceChart 视图
    let initialSeries: LineSeriesOption[] = [];

    if (renderType === 'default') {
      setDrawLoading(true);
    }

    if (renderType === 'default' || renderType === 'popover') {
      initialSeries = [
        {
          name: "QH1",
          type: "line",
          label: renderLabel("Q-H"),
          smooth: true, // 是否平滑
          symbolSize: 0, // 标记的大小
          lineStyle: {
            width: 1,
            // type: [5, 5]
          },
          data: QHDummyPoints,
          color: "#aaa",
          animation: false // 关闭动画
        },
        // {
        //   name: "QH1",
        //   type: "line",
        //   label: renderLabel("Q-H"),
        //   smooth: true,
        //   symbolSize: 0,
        //   lineStyle: {
        //     width: 1,
        //   },
        //   data: QHLine,
        //   color: "#aaa",
        //   animation: false,
        //   // areaStyle: {
        //   //   color: '#000'
        //   // }
        // },
        {
          name: "QEFF",
          type: "line",
          label: renderLabel("Q-EFF", [30, -5], QEFFIndex),
          smooth: true,
          symbolSize: 0,
          lineStyle: {
            width: 1,
          },
          data: QEFF.dummyPoints,
          color: "#aaa",
          xAxisIndex: 0,
          yAxisIndex: 1,
          animation: false
          // areaStyle: {
          //   color: '#fff',
          //   opacity: 1
          // }
        },
        {
          name: "QP1",
          type: "line",
          label: renderLabel("Q-P"),
          smooth: true,
          symbolSize: 0,
          lineStyle: {
            width: 1
          },
          data: QP,
          color: "#aaa",
          xAxisIndex: 1,
          yAxisIndex: 2,
          animation: false
        },
        {
          name: "QNPSH",
          type: "line",
          label: renderLabel("Q-NPSHr"),
          smooth: true,
          symbolSize: 0,
          lineStyle: {
            width: 1
          },
          data: QNPSH ? QNPSH.dummyPoints : [],
          color: "#aaa",
          xAxisIndex: 1,
          yAxisIndex: 3,
          animation: false
        }
      ];
    }

    if (renderType === 'table') {
      initialSeries = [
        {
          name: "QH1",
          type: "line",
          smooth: true, // 是否平滑
          symbolSize: 0, // 标记的大小
          lineStyle: {
            width: 1
          },
          data: QHDummyPoints,
          color: "#aaa",
          animation: false // 关闭动画
        },
        {
          name: "QEFF",
          type: "line",
          smooth: true,
          symbolSize: 0,
          lineStyle: {
            width: 1,
          },
          data: QEFF.dummyPoints,
          color: "#aaa",
          xAxisIndex: 0,
          yAxisIndex: 1,
          animation: false
        }
      ];
    }

    let newOption: EChartsOption = {};

    if (renderType === 'popover') {
      const [hMaxP, hIntervalP] = calcYAxis(
        QHDummyPoints.map((p) => p[1]),
        10,
        1
      );
  
      const [pMaxP, pIntervalP] = calcYAxis(
        QP.map((p) => p[1]),
        5,
        1
      );

      hMax = hMaxP;
      hInterval = hIntervalP;

      pMax = pMaxP;
      pInterval = pIntervalP;
    }

    if (renderType === 'default' || renderType === 'popover') {
      newOption = {
        ...initialOption,
        yAxis: [
          // H
          {
            name: "H(m)",
            type: "value",
            min: 0,
            max: hMax,
            interval: hInterval,
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              hideOverlap: true,
              formatter: function (value: any, index: any) {
                if (index % 5 === 0) {
                  return value;
                }

                return '';
              }
            },
          },
          // EFF
          {
            name: "EFF(%)",
            type: "value",
            min: 0,
            max: 200,
            splitNumber: 10,
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              hideOverlap: true,
              formatter: (v: any) => {
                if (v <= 100) {
                  return v;
                }

                return '';
              }
            }
          },
          // P
          {
            name: "P(kW)",
            type: "value",
            min: 0,
            max: pMax,
            interval: pInterval,
            gridIndex: 1,
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              hideOverlap: true,
              formatter: function (value: any, index: any) {
                if (index % 5 === 0) {
                  return value;
                }

                return '';
              }
            }
          },
          // NPSHr
          {
            name: "NPSHr(m)",
            type: "value",
            gridIndex: 1,
            min: 0,
            max: 15,
            splitNumber: 5,
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: true,
              hideOverlap: true
            }
          }
        ],
        series: initialSeries
      };
    }

    if (renderType === 'table') {
      newOption = {
        ...initialOption,
        yAxis: [
          // H
          {
            name: "H(m)",
            type: "value",
            min: 0,
            max: hMax,
            interval: hInterval,
            silent: true,
            axisLabel: {
              show: false
            }
          },
          // EFF
          {
            name: "EFF(%)",
            type: "value",
            min: 0,
            max: 200,
            splitNumber: 10,
            silent: true,
            axisLabel: {
              show: false
            }
          }
        ],
        series: initialSeries
      };
    }

    // 如果有表单数据，则绘制 QH2/QP2/QEFF2/QNPSH2 和对应文字
    if (quantity !== undefined && head !== undefined) {
      const {
        range
      } = info;

      // 获取区间中点
      const center = (range.start + range.end) / 2;

      // 获取辅助线与QH1的交点
      const intersectionPoint = findIntersectionPoint(
        quantity,
        head,
        QH.equation, // QH 方程式系数的数组
        type,
        center
      );

      if (intersectionPoint) {
        // 生成辅助线数据
        const auxData = createAuxiliaryData(
          quantity,
          head,
          0,
          intersectionPoint[0],
          type
        );

        // 根据交点 生成 K
        const k: number = createK(quantity, intersectionPoint[0], type);

        // 根据K 生成 QH2 [平移]
        const qH2Data = createQH2Data(k, QH.dummyPoints, type);

        // 平移 QEFF
        const qEFF2Data = createQEFF2Data(k, QEFF.dummyPoints, type);

        // 平移 QNPSH
        const qNPSH2Data = createQNPSH2Data(
          k,
          QNPSH ? QNPSH.dummyPoints : [],
          type
        );

        // 平移 QP
        const qH2Points = createQH2Data(k, QH.points as DataPoint[], type);

        const qEFF2Points = createQEFF2Data(
          k,
          QEFF.points as DataPoint[],
          type
        );

        const qP2Data = createQP(qH2Points, qEFF2Points, sg || 1);

        // 计算叶轮尺寸
        const {
          diameter,
          diameterUnit
        } = createDiameterData(
          k,
          info.diameter,
          type
        );

        // 计算转速
        const speed = createSpeedData(k, info.speed, type);

        // 计算频率 [公式2 不计算, 直接返回]
        const frequency = createFrequencyData(k, info.frequency, type);

        // EFF
        const eff = convertEFF1ToEFF2(QEFF, intersectionPoint[0], k);

        // P
        const power = powerFormula([quantity, head], eff / 100) * (sg || 1);

        // NPSH
        // const npsh = QNPSH ? QNPSH.predict(quantity)[1] : undefined;  // 多相式
        const npsh = getNpshrByData(qNPSH2Data, quantity); // catmullRom

        const finalQEFF2Data = qEFF2Data.filter((v: any) => {
          const qEff = v[1];

          return qEff <= 100;
        }); // 过滤掉 效率 > 100的错误数据

        const effK = findClosestNumber(finalQEFF2Data, quantity)
        const npsh2K = findClosestNumber(qNPSH2Data, quantity)
        const qp2K = findClosestNumber(qP2Data, quantity)

        const effQ = effK.length > 0 ? effK[0] : 0;
        const effH = effK.length > 0 ? effK[1] : 0;

        const npsh2Q = npsh2K.length > 0 ? npsh2K[0] : 0;
        const npsh2H = npsh2K.length > 0 ? npsh2K[1] : 0;

        const qp2Q = qp2K.length > 0 ? qp2K[0] : 0;
        const qp2H = qp2K.length > 0 ? qp2K[1] : 0;

        const finalQP2DataObj: any = generateCatmullRomPoints(qP2Data, 500);
        const finalQP2Data = finalQP2DataObj?.dummyPoints || qP2Data;

        if (renderType === 'default' || renderType === 'popover') {
          // 更新表单
          setPerformanceResult({
            diameter,
            speed,
            frequency,
            power,
            npsh,
            eff,
          });

          const defaultSeries: any[] = [
            {
              name: "aux",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: {
                width: 1.2
              },
              color: "black",
              data: auxData,
              animation: false
            },
            {
              name: "QH2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: {
                width: 1.2
              },
              color: "black",
              label: renderLabel(
                type === 0
                  ? `${precision(speed, 0)} RPM`
                  : `${precision(diameter, 0)} ${diameterUnit}`
              ),
              data: qH2Data,
              animation: false
            },
            {
              name: "QEFF2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: {
                width: 1.2,
              },
              xAxisIndex: 0,
              yAxisIndex: 1,
              color: "black",
              data: finalQEFF2Data,
              animation: false
            },
            {
              name: "QNPSH2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: {
                width: 1.2
              },
              xAxisIndex: 1,
              yAxisIndex: 3,
              color: "black",
              data: qNPSH2Data,
              animation: false
            },
            {
              name: "QP2",
              type: "line",
              smooth: true,
              symbolSize: 0,
              lineStyle: {
                width: 1.2
              },
              xAxisIndex: 1,
              yAxisIndex: 2,
              color: "black",
              data: finalQP2Data,
              animation: false
            },
            {
              name: "intersection point",
              type: "custom",
              renderItem: genRenderItem("circle1", quantity, head),
              data: [
                {
                  name: "intersection point",
                  xAxis: quantity,
                  yAxis: head
                }
              ],
              z: 100,
            },
            {
              name: "intersection point",
              type: "custom",
              renderItem: genRenderItem("circle2", quantity, head),
              data: [
                {
                  name: "intersection point",
                  xAxis: quantity,
                  yAxis: head
                }
              ],
              z: 101,
            },
            {
              name: "intersection point",
              type: "custom",
              renderItem: genRenderItem("circle3", quantity, head),
              data: [
                {
                  name: "intersection point",
                  xAxis: quantity,
                  yAxis: head
                }
              ],
              z: 102,
            },
            {
              name: "top info",
              type: "custom",
              renderItem: genRenderItem("text", 365, 65, [
                {
                  label: "Q",
                  value: precision(quantity, 1),
                  unit: "m³/h"
                },
                {
                  label: "H",
                  value: precision(head, 1),
                  unit: "m"
                },
                {
                  label: t("chart.diameter"),
                  value: precision(diameter, 0),
                  unit: diameterUnit
                },
                {
                  label: t("chart.speed"),
                  value: precision(speed, 0),
                  unit: "rpm"
                },
                {
                  label: t("chart.efficiency"),
                  value: precision(eff, 2),
                  unit: "%"
                },
                {
                  label: t("chart.sg"),
                  value: precision(sg || 1, 2)
                }
              ]),
              data: [{ x: 0, y: 0 }],
              z: 100
            },
            {
              name: "bottom info",
              type: "custom",
              renderItem: genRenderItem("text", 365, 490, [
                {
                  label: "P",
                  value: precision(power, 2),
                  unit: "kw"
                },
                {
                  label: "NPSHr",
                  value: npsh ? precision(npsh, 2) : npsh,
                  unit: "m"
                }
              ]),
              data: [{ x: 0, y: 0 }],
              z: 100
            },
          ];

          let effQSeries: any[] = [];

          if (effQ && effH) {
            const finalEffH = effH * (effQ / quantity);

            effQSeries = [
              {
                name: "intersection point",
                type: "custom",
                xAxisIndex: 0,
                yAxisIndex: 1,
                renderItem: genRenderItem("circle3", quantity, finalEffH),
                data: [
                  {
                    name: "intersection point",
                    xAxis: quantity,
                    yAxis: finalEffH
                  }
                ],
                z: 102,
              }
            ];
          }

          let npsh2QSeries: any[] = [];

          if (npsh2Q && npsh2H) {
            const finalNpsh2H = npsh2H * (npsh2Q / quantity);

            npsh2QSeries = [
              {
                name: "intersection point",
                type: "custom",
                xAxisIndex: 1,
                yAxisIndex: 3,
                renderItem: genRenderItem("circle3", quantity, finalNpsh2H),
                data: [
                  {
                    name: "intersection point",
                    xAxis: quantity,
                    yAxis: finalNpsh2H
                  }
                ],
                z: 102,
              }
            ];
          }

          let qp2QSeries: any[] = [];

          if (qp2Q && qp2H) {
            // const finalQp2H = qp2H * (qp2Q / quantity) - 0.06;

            const finalQp2H = power;

            qp2QSeries = [
              {
                name: "intersection point",
                type: "custom",
                xAxisIndex: 1,
                yAxisIndex: 2,
                renderItem: genRenderItem("circle3", quantity, finalQp2H),
                data: [
                  {
                    name: "intersection point",
                    xAxis: quantity,
                    yAxis: finalQp2H
                  }
                ],
                z: 102,
              }
            ];
          }

          // 更新图表
          newOption = {
            ...newOption,
            series: [
              ...initialSeries,
              ...defaultSeries,
              ...effQSeries,
              ...npsh2QSeries,
              ...qp2QSeries
            ]
          };
        }

        if (renderType === 'table') {
          newOption = {
            ...newOption,
            series: [
              ...initialSeries,
              {
                name: "aux",
                type: "line",
                smooth: true,
                symbolSize: 0,
                lineStyle: {
                  width: 1.2
                },
                color: "black",
                data: auxData,
                animation: false
              },
              {
                name: "QH2",
                type: "line",
                smooth: true,
                symbolSize: 0,
                color: "black",
                lineStyle: {
                  width: 1.2,
                },
                data: qH2Data,
                animation: false
              },
              {
                name: "QEFF2",
                type: "line",
                smooth: true,
                symbolSize: 0,
                lineStyle: {
                  width: 1.2
                },
                xAxisIndex: 0,
                yAxisIndex: 1,
                color: "black",
                data: finalQEFF2Data,
                animation: false
              },
            ]
          };
        }
      }
    }

    setOption(newOption);

    if (renderType === 'default') {
      setDrawLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(performance),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(dataset)]
  );

  const { setPerformanceImage } = useSpuPdfParam();

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);
    // 当 excludeComponents 执行后，会导致图表上对应的组件暂时消失。
    // 这里强制执行一次，重新让组件显示出来.
    instance.getDataURL();

    setPerformanceImage(result);
  };

  const handleChartClick = (params: any, chart: any) => {
    const pixel = [params.offsetX, params.offsetY];

    if (chart && chart.containPixel({seriesIndex: 0}, pixel)) {
      const xAxisPointer = chart.getOption().xAxis[0].axisPointer;
      const yAxisPointer = chart.getOption().yAxis[0].axisPointer;

      const {
        value: xValue
      } = xAxisPointer;

      const {
        value: yValue
      } = yAxisPointer;

      const finalXValue: any = parseFloat(xValue.toFixed(2))
      const finalYValue: any = parseFloat(yValue.toFixed(2))

      const {
        type,
        sg
      } = performance;

      console.log(searchParams);

      performanceForm.setFieldsValue({
        quantity: finalXValue,
        head: finalYValue
      });

      setSearchParams({
        q: finalXValue,
        h: finalYValue
      });

      setPerformanceValue({
        type,
        sg,
        quantity: finalXValue,
        head: finalYValue
      });
    }
  }

  if (renderType === 'table') {
    return (
      <Chart
        option={option}
        notMerge
        style={{ width: 80, height: 80 }}
        className='performance-chart-table'
        onChange={handleChartChange}
      />
    );
  }

  if (renderType === 'popover') {
    return (
      <Chart
        option={option}
        notMerge
        style={{ width: 500, height: 700, margin: "auto" }}
        className='performance-chart'
        onChange={handleChartChange}
      />
    )
  }

  return (
    <div className="performance-chart-main">
      {
        drawLoading && (
          <div className="pcm-loading">
            <Spin
              spinning={drawLoading}
              className='pcm-l-spin'
            />
          </div>
        )
      }
      <Chart
        option={option}
        notMerge
        style={{ width: 500, height: 700, margin: "auto" }}
        className='performance-chart'
        onChange={handleChartChange} // 图表变换时的回调函数
        handleChartClick={handleChartClick}
      />
    </div>
  );
};

export default PerformanceChart;
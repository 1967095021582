import './EditSpuInfo.css';
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  InputNumber,
  Spin,
  Modal,
  message,
  Image
} from 'antd'
import { SyncOutlined, CloseOutlined } from '@ant-design/icons'
import spuServices from '../../../services/spu'
import supplierServices from '../../../services/supplier'
import useDrawer from '../../../hooks/useDrawer'
import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from "react-i18next"
import useStorage from '../../../stores/storage'

import AikonCascader from '../../ClassifyList/components/AikonCascader'
import ClassifyEditor from '../../ClassifyList/components/ClassifyEditor'
import FileCard from '../../FileManager/FileCard'
// 编辑 spu

const EditSpuInfo = forwardRef((props: any, ref) => {
  
  const { t } = useTranslation()
  const [infoId, setInfoId] = useState('')
  const [supplierList, setSupplierList] = useState([])
  const [classifyList, setClassifyList] = useState<any>([])
  const [form] = Form.useForm()
  const langList: any = {0:"中文", 1:"English", 2:"русский"}
  const cnEditorRef = useRef<any>(null)
  const enEditorRef = useRef<any>(null)
  const ruEditorRef = useRef<any>(null)
  const [imgUpType, setImgUpType] = useState('image')
  const [editorInsert, setEditorInsert] = useState<any>({})
  const [fileVisible, setFileVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { setView } = useStorage()
  const [updateKey, setUpdateKey] = useState<any>('')
  const [nameLangBase, setNameLangBase] = useState<any>([])
  const [contentLangBase, setContentLangBase] = useState<any>([])
  const [formData, setFormData] = useState({
    catID: '',
    supID: [],
    spu: '',
    seriesNumber: '',
    mainImg: '',
    mainImgPath: '',
    tecImg: '',
    tecImgPath: '',
    orderIndex: 1,
    spuUUID: '',
    contentUUID: '',
    contentLang: [
      {id: "", value: "", lang: 0},
      {id: "", value: "", lang: 1},
      {id: "", value: "", lang: 2},
    ],
    nameLang: [
      {id: "", value: "", lang: 0},
      {id: "", value: "", lang: 1},
      {id: "", value: "", lang: 2},
    ]
  })

  useImperativeHandle(ref, () => ({
    view(id: any) {
      setLoading(true)
      setInfoId(id)
      getOptionData()
      getData(id)
      show()
    }
  }));

  // 处理最后一级无法选中
  const treeParentId = (tree: any[], pid?: any) => {
    for (let i = 0; i < tree.length; i++) {
      let item = tree[i];
      item["parentId"] = pid;
      if(item.parentId && !item.children) {
        // item["disabled"] = true
      }
      if(item.children && item.children.length !== 0) {
        treeParentId(item.children, item.id)
      }
    }
    return tree;
  }

  const getOptionData = async () => {
    try {
      const supplierRes = await supplierServices.getList({page: 1, pageSize: 500, key: ''})
      const catRes = await spuServices.getCategories()
      const { response: spResponse } = supplierRes.data
      const { response: catResponse } = catRes.data
      
      if(spResponse) {
        setSupplierList(spResponse.list.map((item: any) => {
          return {
            label: item.name,
            value: item.id.toString()
          }
        }))
      }
      if(catResponse) {
        let tree = JSON.parse(JSON.stringify(catResponse))
        tree.unshift({id: 0, key: 0, name: "顶级"})
        setClassifyList(treeParentId(tree))
      }
    } catch (error) {
      message.error("error")
    }
  }

  const getData = async (id: any) => {
    const res = await spuServices.getInfo(id)
    const { response } = res.data
    if(response) {
      let data = JSON.parse(JSON.stringify(response.info))
      let params: any = {
        catID: data.cat_id,
        supID: data.sup_id,
        spu: data.spu,
        seriesNumber: data.seriesNumber,
        mainImg: data.main_img,
        mainImgPath: data.sImg,
        tecImg: data.tec_img,
        tecImgPath: data.tec_sImg,
        orderIndex: data.order_index
      }
      if(data.name_lang) {
        let nameLang = []
        for(let i=0; i<formData.nameLang.length; i++) {
          let item = formData.nameLang[i]
          let index = data.name_lang.findIndex((dItem: { lang: number; }) => dItem.lang === item.lang)
          item["value"] = index === -1?'': data.name_lang[index].value
          item["id"] = index === -1?'': data.name_lang[index].id
        }
        nameLang = formData.nameLang
        params["nameLang"] = nameLang
        setNameLangBase(JSON.parse(JSON.stringify(nameLang)))
      }
      
      cnEditorRef.current && cnEditorRef.current.setContent("<p><p>")
      enEditorRef.current && enEditorRef.current.setContent("<p><p>")
      ruEditorRef.current && ruEditorRef.current.setContent("<p><p>")

      setTimeout(() => {
        let contentLang: any = []
        if(data.content_lang && data.content_lang.length) {
          contentLang = formData.contentLang
          for(let i=0; i<contentLang.length; i++) {
            let item = contentLang[i];
            let index = data.content_lang.findIndex((iItem: {type: number, lang: number}) => iItem.type === item.lang || iItem.lang === item.lang)
            if(index > -1) {
              item.value = data.content_lang[index].value
              item.id = data.content_lang[index].id
              item.lang = data.content_lang[index].type || data.content_lang[index].lang;
            }
            if(item.lang === 0 && item.value) cnEditorRef.current && cnEditorRef.current.setContent(item.value)
            if(item.lang === 1 && item.value) enEditorRef.current && enEditorRef.current.setContent(item.value)
            if(item.lang === 2 && item.value) ruEditorRef.current && ruEditorRef.current.setContent(item.value)
          }
          setContentLangBase(JSON.parse(JSON.stringify(contentLang)))
          setFormData(Object.assign(formData, {contentLang: contentLang}))
        }
      }, 1000)

      form.setFieldsValue(params)
      setFormData(Object.assign(formData, params))
      setLoading(false)
      setUpdateKey(new Date().getTime())
    }
  }

  const onNameReset = () => {
    let nameLang = JSON.parse(JSON.stringify(nameLangBase))
    form.setFieldValue('nameLang', nameLang)
  }
  const onNameClear = () => {
    let nameLang = form.getFieldValue('nameLang')
    nameLang.forEach((item: any) => {
      item.value = ''
    })
    form.setFieldValue('nameLang', nameLang)
  }

  /* 编辑器方法 */
  const onContentReset = () => {
    // 重置内容
    let contentLang = formData.contentLang
    let contentLangData = JSON.parse(JSON.stringify(contentLangBase))
    for(let i=0; i<contentLang.length; i++) {
      let item = contentLang[i];
      let index = contentLangData.findIndex((iItem: {lang: number}) => iItem.lang === item.lang)
      if(index > -1) {
        item.value = contentLangData[index].value
        item.id = contentLangData[index].id
        item.lang = contentLangData[index].lang
      }
      if(item.lang === 0 && item.value) cnEditorRef.current && cnEditorRef.current.setContent(item.value)
      if(item.lang === 1 && item.value) enEditorRef.current && enEditorRef.current.setContent(item.value)
      if(item.lang === 2 && item.value) ruEditorRef.current && ruEditorRef.current.setContent(item.value)
    }
  }
  const onContentClear = () => {
    // 清除内容
    let contentLang = formData.contentLang;
    contentLang.forEach((item: { value: string; }) => {
      item.value = ""
    })
    setFormData(Object.assign(formData, {contentLang}));
    cnEditorRef.current && cnEditorRef.current.clearHtml()
    enEditorRef.current && enEditorRef.current.clearHtml()
    ruEditorRef.current && ruEditorRef.current.clearHtml()
  }
  const onSendValue = (val: any, index: number) => {
    // 设置内容
    let contentLang = formData.contentLang;
    contentLang[index].value = val;
    setFormData(Object.assign(formData, {contentLang}));
  }
  const onEditorUp = (insertFn: Function, type: any) => {
    setImgUpType('editor')
    setFileVisible(true)
    setView("grid")
    setEditorInsert({insertFn: insertFn})
  }

  const closeFileVisible = () => {
    setFileVisible(false)
  }
  
  const onImageClick = (type: any) => {
    
    if(type === 'mainImage' && formData.mainImgPath) {
      setFormData(Object.assign(formData, {mainImgPath: '', mainImg: ''}))
      
    } else if(type === 'tecImage' && formData.tecImgPath) {
      setFormData(Object.assign(formData, {tecImgPath: '', tecImg: ''}))

    } else {
      setImgUpType(type)
      setFileVisible(true)
      setView("grid")
    }
    setUpdateKey(new Date().getTime())
  }
  
  const onImageSelected = (item: any) => {
    if(imgUpType === 'mainImage') {
      setFormData(Object.assign(formData, {mainImg: item.id, mainImgPath: item.path}))
    } else if(imgUpType === 'tecImage') {
      setFormData(Object.assign(formData, {tecImg: item.id, tecImgPath: item.path}))
    } else if(imgUpType === 'editor') {
      editorInsert.insertFn(item.path)
    }
    setFileVisible(false)
  }

  const onCascaderChange = (val: any) => {
    setFormData(Object.assign(formData, {catID: val}))
    form.setFieldValue('catID', val)
  }

  const onSubmit = async () => {
    try {
      await form.validateFields()
      setLoading(true)
      let params = {
        catID: formData.catID.toString().split(','),
        supID: form.getFieldValue('supID'),
        spu: form.getFieldValue('spu'),
        seriesNumber: form.getFieldValue('seriesNumber'),
        mainImg: formData.mainImg,
        tecImg: formData.tecImg,
        orderIndex: form.getFieldValue('orderIndex'),
        nameLang: form.getFieldValue('nameLang'),
        contentLang: formData.contentLang
      }

      const res = await spuServices.putSpuInfo(infoId, params)
      const { response } = res.data
      if(response) {
        props.editSuccess()
        hide()
      }
      setLoading(false)
    } catch (error) {
      message.error("Error")
      setLoading(false)
    }
  }

  const { visible, show, hide } = useDrawer();

  const onClose = () => {
    hide()
    form.resetFields()
  }

  const mainImageView = () => {
    return (
      <div key={updateKey}>
        { formData.mainImgPath?(<Image width={100} src={formData.mainImgPath} />):null }
      </div>
    )
  }

  const tecImageView = () => {
    return (
      <div key={updateKey}>
        { formData.tecImgPath?(<Image width={100} src={formData.tecImgPath} />):null }
      </div>
    )
  }

  return (
    <Drawer
      title={t("spuList.edit.drawer.title")}
      visible={visible}
      width={700}
      onClose={onClose}
      extra={
        <Button type="primary" onClick={onSubmit}>
          {t("spuList.edit.drawer.submit")}
        </Button>
      }
    >
      <Spin spinning={loading}>
        <div className='edit-spu-info'>
          <Form
            key={infoId}
            name="basic"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 20 }}
            autoComplete="off"
            form={form}
            initialValues={formData}
            colon={false}
          >
            <Form.Item name="catID" label={t("spuEdit.form.cat")}>
              <AikonCascader 
                fieldNames={{ label: 'name', value: 'id', children: 'children' }} 
                data={classifyList} 
                className="spu-names-input"
                value={formData.catID} 
                radioChange={onCascaderChange}></AikonCascader>
            </Form.Item>
            <Form.Item name="seriesNumber" label={t("spuEdit.form.seriesNumber")}>
              <Input className="spu-names-input spu-input-width" />
            </Form.Item>
            <Form.Item label={t("spuEdit.form.names")}>
              <>
                <div className="spu-names">
                <div className="spu-clear" style={{"marginBottom":"16px"}}>
                  <Button style={{"marginRight":"8px"}} size="small" type="primary" danger onClick={onNameReset}><SyncOutlined /></Button>
                  <Button type="primary" size="small" onClick={onNameClear}><CloseOutlined /></Button>
                </div>
                  <Form.List name="nameLang">
                    {fields =>
                      fields.map(({key, name}) => (
                        <Form.Item 
                          name={[name, "value"]} 
                          label={langList[key]} 
                          labelCol={{ span: 5 }} 
                          key={key} 
                          rules={[{ required: true, message: t("spuEdit.form.placeholder.enter") }]}>
                          <Input className="spu-names-input" />
                        </Form.Item>
                      ))
                    }
                  </Form.List>
                </div>
              </>
            </Form.Item>
            <Form.Item name="supID" label={t("spuEdit.form.supplier")}>
              <Select
                mode="multiple"
                placeholder={t("spuEdit.form.placeholder.select")}
                className="spu-names-input spu-input-width"
                options={supplierList}
              />
            </Form.Item>
            <Form.Item name="spu" label={t("spuEdit.form.spucode")}>
              <Input className="spu-names-input spu-input-width" />
            </Form.Item>
            <Form.Item name="mainImg" label={t("spuEdit.form.mainImg")}>
              <>
                { mainImageView() }
                <Button
                  style={{marginTop: formData.mainImgPath?"16px":"0px"}}
                  type="primary"
                  size="small"
                  danger={!!formData.mainImgPath}
                  className="spu-names-input" 
                  onClick={() => onImageClick('mainImage')}>{formData.mainImgPath?t("spuEdit.form.del"):t("spuEdit.form.select")}</Button>
              </>
            </Form.Item>
            <Form.Item name="tecImg" label={t("spuEdit.form.tecImg")}>
              <>
                { tecImageView() }
                <Button
                  style={{marginTop: formData.tecImgPath?"16px":"0px"}}
                  type="primary"
                  size="small"
                  danger={!!formData.tecImgPath}
                  className="spu-names-input" 
                  onClick={() => onImageClick('tecImage')}>{formData.tecImgPath?t("spuEdit.form.del"):t("spuEdit.form.select")}</Button>
              </>
            </Form.Item>
            <Form.Item name="orderIndex" label={t("spuEdit.form.sort")}>
              <InputNumber min={1} className="spu-names-input spu-input-width" />
            </Form.Item>
            <Form.Item label={t("spuEdit.form.details")}>
              <div className="spu-descs">
                <div className="spu-clear" style={{"margin":"14px 14px 0"}}>
                  <Button style={{"marginRight":"8px"}} size="small" type="primary" danger onClick={onContentReset}><SyncOutlined /></Button>
                  <Button type="primary" size="small" onClick={onContentClear}><CloseOutlined /></Button>
                </div>
                <h3>中文：</h3>
                <ClassifyEditor ref={cnEditorRef} value={formData.contentLang[0].value} sendValue={(val: any) => onSendValue(val, 0)} upImageClick={onEditorUp} />
                <h3>English：</h3>
                <ClassifyEditor ref={enEditorRef} value={formData.contentLang[1].value} sendValue={(val: any) => onSendValue(val, 1)} upImageClick={onEditorUp} />
                <h3>русский：</h3>
                <ClassifyEditor ref={ruEditorRef} value={formData.contentLang[2].value} sendValue={(val: any) => onSendValue(val, 2)} upImageClick={onEditorUp} />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Spin>

      <Modal centered width="1080px" open={fileVisible} footer={null} onCancel={closeFileVisible}>
        <div style={{maxHeight: "700px", overflowY: "auto"}}>
          <FileCard isSelect={true} imageSelected={onImageSelected} />
        </div>
      </Modal>

    </Drawer>
  );
});

export default EditSpuInfo;
import { Space } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import useAuth from "../../../hooks/useAuth";
import Custom from "./Custom";
import Delete from "./Delete";
import Edit from "./Edit";
// import Excel from "./Excel";
import PrintPdf from "./PrintPdf";
import View from "./View";
import Attr from "./Attr";
import Size from "./Size";
import PrintViewPdf from "./PrintViewPdf";
//

import useSpuInfo from "../../../stores/spuInfo";

interface ToolsProps {
  loaded: any;
}

const Tools = ({ loaded }: ToolsProps) => {
  const { xs } = useBreakpoint();
  const { isAdmin } = useAuth();
  const { info } = useSpuInfo();

  let toolButtons: any = [
    <PrintPdf key="pdf" buttonType="default" />
    // <Excel key="excel" buttonType="default" />
  ];

  if (loaded) {
    if (isAdmin) {
      toolButtons = [
        <Attr key="attr" />, // 属性编辑
        <Size key="size" />, // 尺寸编辑
        <Delete key="delete" />, // 删除
        info.pdfViewDisplay ? <PrintViewPdf buttonType="default" key="printViewPdf" /> : null, // 【新】预览导出pdf
        <PrintPdf key="pdf" buttonType="default" />, // 导出pdf
        // <Excel key="excel" buttonType="default" />,
        <View key="view" />, // 查看
        <Custom key="custom" />, // 自定义
        <Edit key="edit" />  // 编辑
      ];
    } else {
      toolButtons = [
        // <Attr key="attr" />, 非管理员不加载
        // <Size key="size" />,
        info.pdfViewDisplay ? <PrintViewPdf buttonType="default" key="printViewPdf" /> : null, 
        <PrintPdf key="pdf" buttonType="default" />,
        // <Excel key="excel" buttonType="default" />,
        <View key="view" />,
        // <Custom key="custom" />
      ];
    }
  }

  return <Space>{xs ? toolButtons.reverse() : toolButtons}</Space>;
};

export default Tools;

import {
  Button,
  Modal,
  Form, 
  Input,
  Spin,
  Image,
  Upload,
  InputNumber,
  Checkbox,
  message
} from 'antd';
import { 
  useRef, 
  useState 
} from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useModal from '../../../hooks/useModal';
import "./printViewPdf.css";

import { toJpeg } from 'html-to-image';
import JsPdf from 'jspdf';
import {
  imageCompress
} from '../../../libs/imageConversion';
import spuServices from '../../../services/spu';
import useSpuPdfParam from "../../../stores/spuPdfParam";
import useSpuPoints from "../../../stores/spuPoints";
import {
  parseSpuPointsDataset,
  findIntersectionPoint,
  createK,
  createQH2Data,
  getImageInfo
} from '../../../utils';
// import { set } from 'lodash';

interface PrintPdfProps {
  buttonType: "primary" | "default";
}

const PrintViewPdf = ({ buttonType = "primary" }: PrintPdfProps) => { 
  const { t } = useTranslation();
  const { 
    visible, 
    show, 
    onOk, 
    onCancel, 
    okText, 
    cancelText
  } = useModal();
  const {
    images: threeImages
  } = useSpuPdfParam();
  const { id } = useParams() as { id: string;};
  const { info, performance, dataset }: any = useSpuPoints();
  const { fieldsValue, fieldsResult } = performance;

  const [editVisible, setEditVisible] = useState(false);
  const [editParams, setEditParams] = useState<any>({
    name: '',
    value: ''
  });
  const [dataList, setDataList] = useState<any>([])
  const [headerConfig, setHeaderConfig] = useState({
    logo: '',
    textOne: '',
    textTwo: ''
  });
  const [footerConfig, setFooterConfig] = useState({
    hrefWebsite: ''
  });
  const paperRefs = useRef<any>([]);
  const [loading, setLoading] = useState(false);
  const [innerBorder, setInnerBorder] = useState(false)
  const [outerBorder, setOuterBorder] = useState(false)
  const [erpId, setErpId] = useState(0)

  const getRef = (el: any, index: number) => {
    paperRefs.current[index] = el
  }

  const [editType, setEditType] = useState('')
  const [editField, setEditField] = useState('')
  const [editIndex, setEditIndex] = useState(-1)
  const [editCIndex, setEditCIndex] = useState(-1)

  const handleEditField = (type: string, data: any, field: string, index: number, cIndex?: number) => {
    if (data) {
      let param: {name?: string, value?: string} = {}

      if (data.hasOwnProperty('key')) {
        param['name'] = data.key
      }

      if (data.hasOwnProperty('value')) {
        param['value'] = data.value
      }

      if (field) {
        param['value'] = data[field]
      }

      setEditParams(param)
    }

    setEditType(type)
    setEditIndex(index)
    setEditField(field)
    setEditCIndex(cIndex !== undefined ? cIndex : -1)
    setEditVisible(true)
  }

  const [editImgType, setEditImgType] = useState('')
  const [editImgField, setEditImgField] = useState('')
  const [editImgIndex, setEditImgIndex] = useState(-1)
  const [editImgCIndex, setEditImgCIndex] = useState(-1)
  const [editImgPreview, setEditImgPreview] = useState('')
  const [editImgVisible, setEditImgVisible] = useState(false);

  const handleEditFile = (type: string, data: any, field: string, index: number, cIndex?: number) => {
    setEditImgType(type)
    setEditImgPreview(data)
    setEditImgIndex(index)
    setEditImgField(field)
    setEditImgCIndex(cIndex !== undefined ? cIndex : -1)
    setEditImgVisible(true)
  }

  const beforeUploadPreview = (file: any) => {
    readBlobAsDataURL(file, (res: any) => {
      setEditImgPreview(res)
    })
  }

  const handleEditImgConfirm = () => {
    if (editImgType === 'one-head') {
      setOneImgData(editImgPreview)
    } else if (editImgType === 'two-content') {
      let list: any = twoContentData || []
      let listItem = list[editImgIndex]

      if (editCIndex !== -1) {
        listItem.children[editImgCIndex].value = editImgPreview
      }

      setTwoContentData(list)
    } else if (editImgType === 'header') {
      let headParam: any = headerConfig
      
      if (editImgField) {
        headParam[editImgField] = editImgPreview
      }

      setHeaderConfig(headParam)
    }

    setEditImgVisible(false)
  }

  const handleEditConfirm = () => {
    if (editType === 'one-head') {
      let list: any = oneHeadData || []
      list[editIndex].key = editParams.name
      list[editIndex].value = editParams.value

      setOneHeadData(list)
    } else if (editType === 'two-head') {
      let list: any = twoHeadData || []
      list[editIndex].key = editParams.name
      list[editIndex].value = editParams.value

      setTwoHeadData(list)
    } else if (editType === 'two-content') {
      let list: any = twoContentData || []
      let listItem = list[editIndex]

      if (editCIndex !== -1) {
        listItem.children[editCIndex].key = editParams.name
        listItem.children[editCIndex].value = editParams.value
      } else {
        if (editField) {
          listItem[editField] = editParams.value
        } else {
          listItem.key = editParams.name
          listItem.value = editParams.value
        }
      }

      setTwoContentData(list)
    } else if (editType === 'header') {
      let headItem: any = headerConfig
      headItem[editField] = editParams.value

      setHeaderConfig(headItem)
    } else if (editType === 'footer') {
      let footerItem: any = footerConfig
      footerItem[editField] = editParams.value
      
      setHeaderConfig(footerItem)
    }

    setEditVisible(false)
  }

  const handleExportPDF = (type?: string) => {
    if (paperRefs.current.length > 0) {
      setLoading(true)
      getImageList(0, paperRefs.current.length - 1, type)
    }
  }

  let pdfImageList: Array<any> = []
  const getImageList = async (index: number, total: number, type?: string) => {
    if (index > total) {
      createPDF()

      return
    }

    let el: any = paperRefs.current[index]
    let ratio = window.devicePixelRatio + 1

    if (type === 'HD') {
      ratio += 1
    }

    toJpeg(el, {
      pixelRatio: ratio,
      imagePlaceholder: ''
    }).then(async (res: any) => {
      
      if (res) {
        let imgUrlText = res

        getImageInfo(imgUrlText, async (imgInfo: any) => {
          if (type === 'HD') {
            pdfImageList.push({
              data: imgUrlText,
              type: imgInfo.width > imgInfo.height ? 'l' : 'p'
            })
  
            getImageList(index + 1, total, type)
          } else {
            let imgBlog = dataURLtoBlob(imgUrlText)
            let res = await imageCompress(imgBlog, 220)
  
            readBlobAsDataURL(res, (item: any) => {
              getImageInfo(imgUrlText, async (cimgInfo: any) => {
                pdfImageList.push({
                  data: item,
                  type: cimgInfo.width > cimgInfo.height ? 'l' : 'p'
                })
  
                getImageList(index + 1, total, type)
              })
            })
          }
        })
      }
    }).catch((err) => {
      console.log("index error:", err);
      message.error("导出错误")
      setLoading(false)
    })
  }

  

  // base64转blob
  const dataURLtoBlob = (url: string) => {
    let arr: any = url.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), i = bstr.length, u8arr = new Uint8Array(i)

    while(i--){
      u8arr[i] = bstr.charCodeAt(i)
    }

    return new Blob([u8arr], {type:mime})
  }

  // blob转baee64
  const readBlobAsDataURL = (blob: Blob, callback: Function) => {

    var a = new FileReader();

    a.onload = function(e: any) {callback(e.target.result);};

    a.readAsDataURL(blob);
  }

  // 生成pdf
  const createPDF = () => {
    const pdf = new JsPdf();
    
    // A4大小，210mm x 297mm，四边不保留边距
    let a4w = 210;
    let a4h = 297;

    pdfImageList.forEach((imgItem: any, index: number) => {
      pdf.addImage(
        imgItem.data,
        'JPEG',
        0,
        0,
        imgItem.type === 'l' ? a4h : a4w, 
        imgItem.type === 'l' ? a4w : a4h
      );

      if (index < pdfImageList.length - 1) {
        let nextItem = pdfImageList[index + 1]
        pdf.addPage("", nextItem.type);
      }
    })

    let fileName = '水泵技术参数表'

    let modelItem: any = oneHeadData.find((item: any) => item.key === 'Model')

    if (erpId === 13 && modelItem && modelItem.value) {
      fileName += modelItem.value
    }

    pdf.save(fileName + '.pdf');
    setLoading(false);
  }

  const getData = async () => {
    try {
      const {
        data: res
      } = await spuServices.getPdfPreviewData(Number(id));
      
      if (res) {
        const {
          response
        } = res;

        if (response) {
          const {
            content,
            header,
            footer,
            erpID
          } = response;

          setErpId(erpID)

          if (header) {
            let headerItem = {
              logo: header.logo || '',
              textOne: header.text.one || '',
              textTwo: header.text.two || ''
            }

            setHeaderConfig(headerItem)
          }

          if (footer) {
            let fooetrItem = {
              hrefWebsite: footer.href.website
            }

            setFooterConfig(fooetrItem)
          }

          if (content) {
            let pageData: any = []
            let pageStyleObj: any = {
              pageOne: 'style-one',
              pageTwo: 'style-two',
              pageThree: 'style-three',
              pageFour: 'style-four',
              pictureList: 'style-images'
            }

            let index = 0;

            for (const key in content) {
              index += 1;

              if (key === 'pictureList') {
                if (content['pictureList']) {
                  let imgList: any = content['pictureList'] || []

                  imgList.forEach((item: any) => {
                    pageData.push({
                      sort: index,
                      type: pageStyleObj[key],
                      data: item
                    })
                    
                    index += 1;
                  })
                }
              } else {
                pageData.push({
                  sort: index,
                  type: pageStyleObj[key] || '',
                  data: content[key]
                })
  
                if (key === 'pageOne') {
                  setStyleOneData(content[key])
                } else if (key === 'pageTwo') {
                  setStyleTwoData(content[key])
                } else if (key === 'pageThree') {
                  setStyleThreeData(content[key])
                } else if (key === 'pageFour') {
                  setStyleFourData(content[key])
                }
              }
            }
            
            setDataList(pageData)
          }
        }
      }
    } catch (error) {
      console.log('error', error);
      
    }
  }

  const getStylePage = (type: string, data?: any) => {
    let pageItem: any = null

    switch (type) {
      case 'style-one':
        pageItem = pageStyleOne()
        break;
      case 'style-two':
        pageItem = pageStyleTwo()
        break;
      case 'style-three':
        pageItem = pageStyleThree()
        break;
      case 'style-four':
        pageItem = pageStyleFour()
        break;
      case 'style-images':
        pageItem = pageStyleImages(data)
        break;
      default:
        break;
    }

    return pageItem
  }

  const handleSortEnter = (num: number, index: number) => {
    let list: Array<any> = [...dataList];
    
    list = list.sort((a: any, b: any) => {
      return a.sort  - b.sort;
    })

    setDataList(list);
  }

  const setDataListSort = (num: number, index: number) => {
    let list: Array<any> = [...dataList];
    list[index].sort = num;
    
    setDataList(list);
  }

  const handleOpenModal = () => {
    getData()
    show()
  }

  // 页面数据
  const [oneHeadData, setOneHeadData] = useState([])
  const [oneImgData, setOneImgData] = useState('')
  const [oneDescData, setOneDescData] = useState('')

  const [twoHeadData, setTwoHeadData] = useState([])
  const [twoContentData, setTwoContentData] = useState([])

  const [threeImgData, setThreeImgData] = useState('')
  const [threeContentData, setThreeContentData] = useState([])
  const [threeHeadData] = useState([])
  const [threeCurveTitle, setThreeCurveTitle] = useState('')

  const [fourImgData, setFourImgData] = useState('')
  const [fourImg2Data, setFourImg2Data] = useState('')
  const [fourContentData, setFourContentData] = useState([])
  const [fourHtmlData, setFourHtmlData] = useState('')

  // const [imagesData, setImagesData] = useState([])

  const setStyleOneData = (dataList: any) => {
    if (dataList) {
      let headList: any = [];
      
      for (const key in dataList) {
        if (key === 'cateBImg') {
          let img = dataList[key]?.value || '';
  
          setOneImgData(img);
        } else if (key === 'cateContent') {
          let desc = dataList[key]?.value || '';

          setOneDescData(desc)
        } else {
          headList.push(dataList[key]);
        }
      }

      setOneHeadData(headList)
    }
  }

  const setStyleTwoData = (dataList: any) => {
    const {
      quantity,
      head,
      type
    } = fieldsValue || {}

    const {
      range
    } = info || {}
    
    // let rangeStr = ''
    
    // if (range && range.end !== undefined && range.start !== undefined) {
    //   rangeStr = `${range.start} ~ ${range.end}`
    // }

    const {
      eff,
      // frequency,
      // power,
      // speed,
      diameter,
      npsh
    } = fieldsResult || {}

    const { QH } = parseSpuPointsDataset(dataset);
    
    let effVal = eff !== undefined ? eff.toFixed(2) : ''
    // let frequencyVal = frequency !== undefined ? frequency.toFixed(2) : ''
    // let powerVal = power !== undefined ? power.toFixed(2) : ''
    // let speedVal = speed !== undefined ? speed.toFixed(0) : ''
    let diameterVal = diameter !== undefined ? diameter.toFixed(0) : ''
    let npshVal = npsh !== undefined ? npsh.toFixed(2) : ''
    let rangeEnd = range !== undefined && range.end ? range.end : ''
    let hmVal: any = ''
    
    if (quantity !== undefined && head !== undefined) {
      // 获取区间中点
      const center = (range.start + range.end) / 2;

      // 获取辅助线与QH1的交点
      const intersectionPoint = findIntersectionPoint(
        quantity,
        head,
        QH.equation, // QH 方程式系数的数组
        type,
        center
      );

      if (intersectionPoint) {
        // 根据交点 生成 K
        const k: number = createK(quantity, intersectionPoint[0], type);

        // 根据K 生成 QH2 [平移]
        const qH2Data = createQH2Data(k, QH.dummyPoints, type);

        if (qH2Data && qH2Data.length > 0) {
          let hmNum = qH2Data[0][1]
          hmVal = hmNum.toFixed(2)
        }
      }
    }

    let fieldItems: any = {
      'dutyFlow': quantity || '',
      'dutyHead': head || '',
      'maxHead': hmVal,
      'efficiencyAtDutyFlow': effVal,
      'availableSystemNPSH': npshVal,
      'dutyPointImpellerDiameter': diameterVal,
      'outletPressure': head || '',
      'NPSHrAtRatedFlow': npshVal,
      'maxAllowanceFlow': rangeEnd
    }

    if (dataList) {
      let headList: any = [];
      let contentList: any = [];
      
      for (const key in dataList) {
        if (key === 'basicData') {
          headList = getListInData(dataList[key])
        } else {
          let childItem = dataList[key]

          let items: any = {}
          if (childItem) {
            for (const cKey in childItem) {
              let citem = childItem[cKey] || {}

              if (fieldItems[cKey] !== undefined) {
                citem['value'] = fieldItems[cKey]
              }

              if (cKey !== 'title') {
                items[cKey] = childItem[cKey]
              }
            }
          }

          let dataObj = {
            title: childItem['title']?.key || '',
            children: getListInData(items)
          }

          contentList.push(dataObj)
        }
      }

      setTwoHeadData(headList)
      setTwoContentData(contentList)
    }
  }

  const setStyleThreeData = (data: any) => {
    const {
      quantity,
      head,
      type
    } = fieldsValue || {}

    const {
      range
    } = info || {}
    
    let rangeStr = ''
    
    if (range && range.end !== undefined && range.start !== undefined) {
      rangeStr = `${range.start} ~ ${range.end}`
    }

    const {
      eff,
      frequency,
      power,
      speed
    } = fieldsResult || {}

    const { QH } = parseSpuPointsDataset(dataset);
    
    let effVal = eff !== undefined ? eff.toFixed(2) : ''
    let frequencyVal = frequency !== undefined ? frequency.toFixed(2) : ''
    let powerVal = power !== undefined ? power.toFixed(2) : ''
    let speedVal = speed !== undefined ? speed.toFixed(0) : ''
    let hmVal: any = ''
    
    if (quantity !== undefined && head !== undefined) {
      // 获取区间中点
      const center = (range.start + range.end) / 2;

      // 获取辅助线与QH1的交点
      const intersectionPoint = findIntersectionPoint(
        quantity,
        head,
        QH.equation, // QH 方程式系数的数组
        type,
        center
      );

      if (intersectionPoint) {
        // 根据交点 生成 K
        const k: number = createK(quantity, intersectionPoint[0], type);

        // 根据K 生成 QH2 [平移]
        const qH2Data = createQH2Data(k, QH.dummyPoints, type);

        if (qH2Data && qH2Data.length > 0) {
          let hmNum = qH2Data[0][1]
          hmVal = hmNum.toFixed(2)
        }
      }
    }

    let fieldItems: any = {
      'ratedFlow': quantity || '',
      'headAtRated': head || '',
      'HMax': hmVal,
      'efficiencyAtRatedFlow': effVal,
      'shaftPowerAtRatedFlow': powerVal,
      'frequency': frequencyVal,
      'operationSpeed': speedVal,
      'allowanceCapacityRange': rangeStr,
    }

    let contentList: any = []

    if (data) {
      for (const key in data) {
        let item = data[key] || {}

        if (fieldItems[key] !== undefined) {
          item['value'] = fieldItems[key]
        }

        if (key === 'performanceCurve') {
          setThreeCurveTitle(item.key || '')
        } else {
          contentList.push(item);
        }
      }
    }

    // contentList.push({ key: 'Rated Flow', value: quantity || '' });
    // contentList.push({ key: 'Head at rated (m)', value: head || '' });
    // contentList.push({ key: 'H Max(Q=0)', value: hmVal });
    // contentList.push({ key: 'Effiency at rated flow(%)', value: effVal });
    // contentList.push({ key: 'Shaft power at rated flow(KW)', value: powerVal });
    // contentList.push({ key: 'Frequency(HZ)', value: frequencyVal });
    // contentList.push({ key: 'Operation Speed(RPM)', value: speedVal });
    // contentList.push({ key: 'Allowance capaicty range(m³/h)', value: rangeStr });

    setThreeContentData(contentList)

    if (threeImages) {
      const {
        performance = ''
      } = threeImages

      setThreeImgData(performance)
    }
  }

  const setStyleFourData = (data: any) => {
    if (!data) return;

    const {
      sizeDataType,
      sizeImage,
      sizeData,
      installSizeImage
    } = data;

    setFourImgData(sizeImage || '')
    setFourImg2Data(installSizeImage || '')

    if (sizeDataType === 1) {
      setFourHtmlData(sizeData)
      setFourContentData([])
    } else {
      let tableData = sizeData.map((td: any) => {
        return {
          key: td.name,
          value: td.value
        }
      }) || []

      setFourHtmlData('')
      setFourContentData(tableData)
    }
  }

  // 获取对象数据进行for in 组装成数组返回
  const getListInData = (dataObj: any) => {
    let list: any = []

    for (const key in dataObj) {
      list.push(dataObj[key])
    }

    return list
  }

  const pageStyleOne = () => {
    return (
      <div className={`page-style-one ${outerBorder ? 'page-border' : ''}`}>
        <div className="item-one-data">
          {
            oneHeadData.map((item: any, index: number) => (
              <div 
                className={`cursor-item ${innerBorder ? 'style-item-border' : ''}`} 
                onClick={() => handleEditField('one-head', item, '', index)}
              >
                <p className="text-desc">
                  {item.key}
                </p>
                {
                  item.value ? (<p className="text-value">{ item.value }</p>) : null
                }
              </div>
            ))
          }
        </div>
        
        {
          oneImgData ? <img
            alt=""
            className="data-img cursor-item" 
            src={oneImgData}
            onClick={() => handleEditFile('one-head', oneImgData, 'oneImgData', -1)}
            onLoad={() => {console.log('oneImgData load finish')}}
          /> : null
        }
        <div className="item-one-data">
          <p className="text-value text-value-line40" dangerouslySetInnerHTML={{ __html: oneDescData }}></p>
        </div>
      </div>
    )
  }
  
  const pageStyleTwo = () => {
    return (
      <div className={`page-style-two ${outerBorder ? 'page-border' : ''}`}>
        <div className="style-head">
          {
            twoHeadData.map((item: any, index: number) => (
              <div 
                className={`cursor-item ${innerBorder ? 'style-item-border' : ''}`} 
                onClick={() => handleEditField('two-head', item, '', index)}
              >
                <p className="text-desc">{ item.key }</p>
                <p className="text-value">{ item.value }</p>
              </div>
            ))
          }
        </div>
        <div className="style-content">
          {
            twoContentData.map((item: any, index: number) => (
              <div>
                <div
                  className="text-title cursor-item"
                  onClick={() => handleEditField('two-content', item, 'title', index)}
                >
                  { item.title }
                </div>
                {
                  item.children.map((child: any, cIndex: number) => {
                    if (child.type === 'img') {
                      return (
                        // 565 * 376
                        <div>
                          <div 
                            className={`style-item-img cursor-item ${innerBorder ? 'style-item-border' : ''}`}
                            style={{backgroundImage: `url(${child.value})` }}
                            onClick={() => handleEditFile('two-content', child.value, '', index, cIndex)}
                          >
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div 
                          className={`style-item cursor-item ${innerBorder ? 'style-item-border' : ''}`}
                          onClick={() => handleEditField('two-content', child, '', index, cIndex)}
                        >
                          <p className="text-desc">{ child.key }</p>
                          <p className="text-value">{ child.value }</p>
                        </div>
                      )
                    }
                  })
                }
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  
  const pageStyleThree = () => {
    return (
      <div className={`page-style-three ${outerBorder ? 'page-border' : ''}`}>
        <div className="style-head">
          {
            threeHeadData.map((item: any, index: number) => (
              <div>
                <p className="text-desc">{ item.key }</p>
                <p className="text-value">{ item.value }</p>
              </div>
            ))
          }
        </div>
        <div className='cover-title'>{threeCurveTitle}</div>
        {
          threeImgData ? <img alt="" className="style-img" src={threeImgData} /> : null
        }
        <div className="style-content">
          {
            threeContentData.map((item: any, index: number) => (
              <div className={`${innerBorder ? 'style-item-border' : ''}`}>
                <p className="text-desc">{ item.key }</p>
                <p className="text-value">{ item.value }</p>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
  
  const pageStyleFour = () => {
    return (
      <div className={`page-style-four ${outerBorder ? 'page-border' : ''}`}>
        {
          fourImgData ? <img alt="" className="style-img" src={fourImgData} /> : null
        }
        {
          fourHtmlData ? (
            <div className="html-table" dangerouslySetInnerHTML={{__html: fourHtmlData}}></div>
          ) : (
            <div className="style-table">
              {
                fourContentData.map((item: any, index: number) => (
                  <div className="table-item">
                    <div className="table-item-th">{ item.key }</div>
                    <div className="table-item-td">{ item.value }</div>
                  </div>
                ))
              }
            </div>
          )
        }
        {
          fourImg2Data ? <img alt="" className="style-img2" src={fourImg2Data} /> : null
        }
      </div>
    )
  }

  const pageStyleImages = (data: any) => {
    let imagesData = data
    
    return (
      <div className={`page-style-images ${outerBorder ? 'page-border' : ''}`}>
        <p className='style-img-desc'>{imagesData.text}</p>
        <div>
          {
            imagesData && imagesData.image 
            ? <img className="style-img" src={imagesData.image} alt="" /> 
            : null
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <Button type={buttonType} onClick={handleOpenModal}>
        { t("exPdf.title") }
      </Button>
      <Modal
        open={visible}
        title={ t("exPdf.title") }
        width={'90vw'}
        centered
        bodyStyle={{
          padding: "0",
          height: "82vh",
          background: "#E2E8F0"
        }}
        onOk={onOk} 
        onCancel={onCancel} 
        okText={okText}
        cancelText={cancelText}
        footer={[
          <Checkbox 
            checked={outerBorder} 
            onChange={(e: any) => setOuterBorder(e.target.checked)}
          >
            { t("exPdf.outBorder") }
          </Checkbox>,
          <Checkbox 
            checked={innerBorder} 
            onChange={(e: any) => setInnerBorder(e.target.checked)}
          >
            { t("exPdf.innerBorder") }
          </Checkbox>,
          <Button 
            key="standard" 
            type="primary"
            loading={loading}
            onClick={() => handleExportPDF('stand')}
          >
            { t("exPdf.confirmZip") }
          </Button>,
          <Button 
            key="original" 
            type="primary"
            loading={loading}
            onClick={() => handleExportPDF('HD')}
          >
            { t("exPdf.confirmOriginal") }
          </Button>,
        ]}
      >
        <Spin wrapperClassName="export-spin" spinning={loading}>
          <div className="print-layout">
            <div className={`right-content right-content-${erpId}`}>
              {
                dataList.map((refItem: any, refIndex: number) => {
                  return (
                    <div className="pages-item">
                      <div className="pages-item-number">
                        { t("exPdf.sortNumber") }：
                        <InputNumber 
                          min={1}
                          step={1}
                          value={refItem.sort}
                          onChange={(num: any) => setDataListSort(num, refIndex)}
                          onBlur={(e: any) => handleSortEnter(e, refIndex)}
                          onStep={(e: any) => handleSortEnter(e, refIndex)}
                        />
                      </div>
                      <div 
                        className={`pages-content pages-content-${refItem.type}`}
                        key={refIndex}
                        id={'pages-content-' + refIndex}
                        ref={(e: any) => getRef(e, refIndex)}
                      >
                        <div className="paper-header">
                          <div 
                            className="cursor-item" 
                            onClick={() => handleEditFile('header', headerConfig.logo, 'logo', 0)}
                          >
                            <img
                              alt=""
                              className="left-icon" 
                              src={headerConfig.logo}
                            />
                          </div>
                          <div className="header-right">
                            <h3 
                              className="right-title cursor-item" 
                              onClick={() => handleEditField('header', headerConfig, 'textOne', 0)}
                            >
                              { headerConfig.textOne }
                            </h3>
                            <p 
                              className="right-desc cursor-item"
                              onClick={() => handleEditField('header', headerConfig, 'textTwo', 0)}
                            >
                              { headerConfig.textTwo }
                            </p>
                          </div>
                        </div>
                        {
                          getStylePage(refItem.type, refItem.data)
                        }
                        <div className="paper-footer">
                          <div></div>
                          <div>{ refIndex + 1 }</div>
                          <div 
                            className="cursor-item" 
                            onClick={() => handleEditField('footer', footerConfig, 'hrefWebsite', 0)}
                          >
                            { footerConfig.hrefWebsite}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              
              <Modal
                open={editVisible} 
                title={ t("exPdf.editFieldTitle") }
                okText={okText}
                cancelText={cancelText}
                width={'400px'}
                mask={false}
                centered
                onCancel={() => setEditVisible(false)}
                onOk={handleEditConfirm}
              >
                <div>
                  <Form layout="vertical">
                    {
                      editParams.name !== undefined ? (
                        <Form.Item label={ t("exPdf.editFieldLabel") }>
                          <Input 
                            placeholder={ t("exPdf.editFieldLabelPlac") }
                            value={editParams.name}
                            onChange={(e) => setEditParams({...editParams, name: e.target.value})}
                          />
                        </Form.Item>
                      ) : null
                    }
                    <Form.Item label={ t("exPdf.editFieldValue") }>
                      <Input 
                        placeholder={ t("exPdf.editFieldLabelPlac") }
                        value={editParams.value}
                        onChange={(e) => setEditParams({...editParams, value: e.target.value})}
                      />
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
              <Modal
                open={editImgVisible} 
                title={ t("exPdf.editImgTitle") }
                okText={okText}
                cancelText={cancelText}
                width={'400px'}
                mask={false}
                centered
                onCancel={() => setEditImgVisible(false)}
                onOk={handleEditImgConfirm}
              >
                <div>
                  <Image
                    width={200}
                    src={editImgPreview}
                  />
                  <br />
                  <Upload 
                    beforeUpload={beforeUploadPreview}
                    accept="image/*"
                    showUploadList={false}
                    customRequest={() => {}}
                  >
                    <Button 
                      type="primary" 
                      style={{marginTop: '10px'}}
                      
                    >{ t("exPdf.editImgUptext") }</Button>
                  </Upload>
                </div>
              </Modal>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  )
}

export default PrintViewPdf;
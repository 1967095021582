import { useEffect, useRef, useState } from "react";

import {
  Menu,
  Space,
  Dropdown,
  Button,
  Input,
  PageHeader,
  Form,
  Card,
  Table,
  ConfigProvider,
  Upload,
  message,
  Drawer,
  Select,
  InputNumber,
  Spin,
  Modal,
  Image
} from "antd";

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownOutlined,
  UpOutlined,
  UnorderedListOutlined,
  CloseOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import { useTranslation } from "react-i18next";

import { useNavigate, useSearchParams } from "react-router-dom";

import spuServices from "../../services/spu";

import useAuth from "../../hooks/useAuth";
import useLoading from "../../hooks/useLoading";

import { langMap } from "../../i18n";

import { BASE_URL } from "../../libs/axios";

import { parseSelected } from "../../utils";

import BaseLayout from "../../components/BaseLayout";
import CategorySelector from "../../components/CategorySelector";

import EditSpuInfo from "./components/EditSpuInfo";

import AikonCascader from '../ClassifyList/components/AikonCascader'
import ClassifyEditor from '../ClassifyList/components/ClassifyEditor'
import FileCard from '../FileManager/FileCard'
import useStorage from '../../stores/storage'
import supplierServices from '../../services/supplier'

import './components/excel.css';

const { confirm } = Modal

// spu 列表

const UploadDropdown = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [uploading, setUploading] = useState(false);

  if (!auth) {
    return <></>;
  }

  const props = {
    name: "file",
    action: `${BASE_URL}/spus/import/information`,
    headers: { authorization: `Bearer ${auth.token}` },
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onChange: (info: any) => {
      if (info.file.status !== "uploading") {
        setUploading(false);
      }
      if (info.file.status === "uploading" && !uploading) {
        setUploading(true);
      }
      if (info.file.status === "done") {
        message.success(
          t("spuList.extra.upload.success", {
            import_count: info.file.response.response.import_count,
          })
        );
      }
      if (info.file.status === "error") {
        message.error(t("spuList.extra.upload.error"));
      }
    },
    showUploadList: false,
    multiple: false,
  };

  const menu = (
    <Menu>
      <Menu.Item key="size">
        <Upload {...props} data={{ type: 1 }}>
          {t("modelList.upload.size")}
        </Upload>
      </Menu.Item>
      <Menu.Item key="attribute">
        <Upload {...props} data={{ type: 2 }}>
          {t("modelList.upload.attribute")}
        </Upload>
      </Menu.Item>
      <Menu.Item key="curve">
        <Upload
          name="file"
          action={`${BASE_URL}/spus/points/import`}
          headers={{ authorization: `Bearer ${auth.token}` }}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(info: any) => {
            if (info.file.status !== "uploading") {
              setUploading(false);
            }
            if (info.file.status === "uploading" && !uploading) {
              setUploading(true);
            }
            if (info.file.status === "done") {
              message.success(
                t("spuList.extra.upload.success", {
                  import_count: info.file.response.response.import_count,
                })
              );
            }
            if (info.file.status === "error") {
              message.error(t("spuList.extra.upload.error"));
            }
          }}
          showUploadList={false}
          multiple={false}
        >
          {t("modelList.upload.curve")}
        </Upload>
      </Menu.Item>
      <Menu.Item key="preview">
        <Upload
          name="file"
          action={`${BASE_URL}/spus/pdfPreviewDataImport`}
          headers={{ authorization: `Bearer ${auth.token}` }}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={async (info: any) => {
            if (info.file.status !== "uploading") {
              setUploading(false);
            }
            if (info.file.status === "uploading" && !uploading) {
              setUploading(true);
            }
            if (info.file.status === "done") {
              message.success(
                t("spuList.extra.upload.success", {
                  import_count: info.file.response.response.import_count,
                })
              );
            }
            if (info.file.status === "error") {
              message.error(t("spuList.extra.upload.error"));
            }
          }}
          showUploadList={false}
          multiple={false}
        >
          {t("modelList.upload.preview")}
        </Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button type="default" loading={uploading}>
        <Space>
          {t("modelList.upload.button")} <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

interface ExportDropdownProps {
  query: any;
  selected: any;
}

const ExportDropdown = ({ query, selected }: ExportDropdownProps) => {
  const { t } = useTranslation();

  const handleExportSize = async () => {
    try {
      const finalParam: any = {};
  
      const cat = selected.join(",") || undefined;
  
      if (query !== '' && query !== undefined) {
        finalParam.q = query;
      }
  
      if (cat) {
        finalParam.cat = cat;
      }
  
      const res = await spuServices.postSizeBatch(finalParam);
  
      const { data } = res;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/xlsx" })
      );
  
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hour = now.getHours();
      const min = now.getMinutes();
      const sec = now.getSeconds()
  
      const name = `spu-size-${year}${month}${day}${hour}${min}${sec}`;
  
      const download = document.createElement("a");
      download.href = url;
      download.download = `${name}.xlsx`;
      download.click();
    } catch (e) {
      console.error(e);
    }
  };

  const handleExportAttr = async () => {
    try {
      const finalParam: any = {};
  
      const cat = selected.join(",") || undefined;
  
      if (query !== '' && query !== undefined) {
        finalParam.q = query;
      }
  
      if (cat) {
        finalParam.cat = cat;
      }
  
      const res = await spuServices.postExcelBatch(finalParam);
  
      const { data } = res;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/xlsx" })
      );
  
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hour = now.getHours();
      const min = now.getMinutes();
      const sec = now.getSeconds()
  
      const name = `spu-attr-${year}${month}${day}${hour}${min}${sec}`;
  
      const download = document.createElement("a");
      download.href = url;
      download.download = `${name}.xlsx`;
      download.click();
    } catch (e) {
      console.error(e);
    }
  };

  const handleExportCurve = async () => {
    try {
      const finalParam: any = {};
  
      const cat = selected.join(",") || undefined;
  
      if (query !== '' && query !== undefined) {
        finalParam.q = query;
      }
  
      if (cat) {
        finalParam.cat = cat;
      }
  
      const res = await spuServices.postCurveBatch(finalParam);
  
      const { data } = res;
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/xlsx" })
      );
  
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const day = now.getDate();
      const hour = now.getHours();
      const min = now.getMinutes();
      const sec = now.getSeconds()
  
      const name = `spu-curve-${year}${month}${day}${hour}${min}${sec}`;
  
      const download = document.createElement("a");
      download.href = url;
      download.download = `${name}.xlsx`;
      download.click();
    } catch (e) {
      console.error(e);
    }
  };

  const [onSize, sizeing] = useLoading(handleExportSize);
  const [onAttr, attring] = useLoading(handleExportAttr);
  const [onCurve, curveing] = useLoading(handleExportCurve);

  const handleMenu = (item: any) => {
    const {
      key
    } = item;

    if (key === 'size') {
      onSize();
    }

    if (key === 'attribute') {
      onAttr();
    }

    if (key === 'curve') {
      onCurve();
    }

    if (key === 'preview') {
      window.open("https://file.aikonchina.com/pdf%E9%A2%84%E8%A7%88%E5%8F%82%E6%95%B0%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx", '_blank');
    }
  }

  const menu = (
    <Menu onClick={handleMenu}>
      <Menu.Item key="size">
        <span>
          {t("modelList.upload.size")}
        </span>
      </Menu.Item>
      <Menu.Item key="attribute">
        <span>
          {t("modelList.upload.attribute")}
        </span>
      </Menu.Item>
      <Menu.Item key="curve">
        <span>
          {t("modelList.upload.curve")}
        </span>
      </Menu.Item>
      <Menu.Item key="preview">
        <span>
          {t("modelList.export.preview")}
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        className="spu-list-page-header-extra-export"
        type="default"
        loading={sizeing || attring || curveing}
      >
        <Space>
          {t("modelList.export.button")} <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

interface SpuState {
  id: number;
  key: number;
  name: string;
  spu: string;
  series_number: string;
  hasPoints: boolean;
}

type QueryParams = {
  query: string;
};

const SpuList = () => {
  const [expanded, setExpanded] = useState(true);
  const [spuListDataSource, setSpuListDataSource] = useState<SpuState[]>([]);
  const { isAdmin } = useAuth();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRef] = Form.useForm();
  const [selectorRef] = Form.useForm();
  const editSpuRef = useRef<any>();

  const [infoId] = useState('');
  const langList: any = {0: "中文", 1: "English", 2: "русский"};
  const [supplierList, setSupplierList] = useState([]);
  const [updateKey, setUpdateKey] = useState<any>('');
  const [imgUpType, setImgUpType] = useState('image');
  const [form] = Form.useForm();
  const [fileVisible, setFileVisible] = useState(false);
  const [classifyList, setClassifyList] = useState<any>([]);
  const [editorInsert, setEditorInsert] = useState<any>({});
  const [loadings, setLoading] = useState(false);
  const { setView } = useStorage();
  const cnEditorRef = useRef<any>(null);
  const enEditorRef = useRef<any>(null);
  const ruEditorRef = useRef<any>(null);
  const [formData, setFormData] = useState({
    catID: '',
    supID: [],
    spu: '',
    seriesNumber: '',
    mainImg: '',
    mainImgPath: '',
    tecImg: '',
    tecImgPath: '',
    orderIndex: 1,
    spuUUID: '',
    contentUUID: '',
    contentLang: [
      {id: "", value: "", lang: 0},
      {id: "", value: "", lang: 1},
      {id: "", value: "", lang: 2},
    ],
    nameLang: [
      {id: "", value: "", lang: 0},
      {id: "", value: "", lang: 1},
      {id: "", value: "", lang: 2},
    ]
  })

  // 处理最后一级无法选中
  const treeParentId = (tree: any[], pid?: any) => {
    for (let i = 0; i < tree.length; i++) {
      let item = tree[i];
      item["parentId"] = pid;

      if (item.parentId && !item.children) {
        // item["disabled"] = true
      }
      
      if (item.children && item.children.length !== 0) {
        treeParentId(item.children, item.id);
      }
    }

    return tree;
  }

  const handleOpen = () => {
    setLoading(true);
    // setInfoId(id)
    getOptionData();

    setFormData(Object.assign(formData, {
      catID: '',
      mainImg: '',
      mainImgPath: '',
      tecImg: '',
      tecImgPath: '',
    }));

    form.resetFields();
    
    setIsAddModalVisible(true);
  }

  const getOptionData = async () => {
    try {
      const supplierRes = await supplierServices.getList({page: 1, pageSize: 500, key: ''})
      const catRes = await spuServices.getCategories()
      const { response: spResponse } = supplierRes.data
      const { response: catResponse } = catRes.data
      
      if(spResponse) {
        setSupplierList(spResponse.list.map((item: any) => {
          return {
            label: item.name,
            value: item.id.toString()
          }
        }))
      }

      if(catResponse) {
        let tree = JSON.parse(JSON.stringify(catResponse))
        tree.unshift({id: 0, key: 0, name: "顶级"})        
        setClassifyList(treeParentId(tree))

        setLoading(false)
      }
    } catch (error) {
      message.error("error")
    }
  }

  const navgiate = useNavigate();

  let query: string = searchParams.get("query") || "";
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  let selected: number[] = parseSelected(searchParams.getAll("c"));
  const [total, setTotal] = useState<number>(0);
  

  const handleSubmit = (values: QueryParams) => {
    const { query } = values;
    const { category } = selectorRef.getFieldsValue();

    setSearchParams({ query, page: "1", pageSize: "20", c: category.selected });
  };

  const handleReset = () => {
    searchRef.resetFields();
    selectorRef.resetFields();
    setSearchParams({});
  };

  const handleFormChange = () => {
    searchRef.setFieldsValue({ query });
    selectorRef.setFieldsValue({ category: { selected } });
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    // const cat = selected.join(",") || undefined;

    let strList = selected.map((num: number) => num.toString())
    
    // setSpuListDataSource([]);
    setSearchParams({
      query,
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
      c: strList && strList.length > 0 ? strList : ''
    });
  };

  const getSpuList = async () => {
    const cat = selected.join(",") || undefined;

    const result = await spuServices.getList({
      q: query,
      page: page,
      limit: pageSize,
      cat: cat
    });

    const { totalCount, spus } = result.data.response;

    // update total
    setTotal(totalCount);
    // update data source
    setSpuListDataSource(
      spus.map((spu: any) => {
        return {
          id: spu.id,
          key: spu.id,
          name: spu.spu_name,
          spu: spu.spu,
          series_number: spu.series_number,
          hasPoints: spu.has_points,
        };
      })
    );
  };

  const showConfirm = (id: number) => {
    confirm({
      title: t("spuList.extra.delete.title"),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const res = await spuServices.deleteSpuSizeInfo(id)

        if (res) {
          message.success(t("spuList.extra.delete.success"));

          getSpuList();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleMenuClick = (param: any, record: any) => {
    const {
      key
    } = param;

    const {
      key: rKey,
      id
    } = record;

    if (key === 'chart') {
      navgiate(`/spus/${rKey}`);
    }

    if (key === 'edit') {
      editSpuRef && editSpuRef.current?.view(rKey)
    }

    if (key === 'attr') {
      window.open(`/spus/attr/${rKey}`, '_blank');
    }

    if (key === 'delete') {
      showConfirm(id)
    }
  };

  const onEditSuccess = () => {
    onPageChange();
  }

  // 新增功能
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)

  const hideIsAddModalVisible = () => {
    setIsAddModalVisible(false);

    setLoading(false);

    getSpuList();
  }

  const onCascaderChange = (val: any) => {
    setFormData(Object.assign(formData, {catID: val}));
    form.setFieldValue('catID', val);
  }

  const onNameClear = () => {
    let nameLang = form.getFieldValue('nameLang');

    nameLang.forEach((item: any) => {
      item.value = '';
    })

    form.setFieldValue('nameLang', nameLang);
  }

  const mainImageView = () => {
    return (
      <div key={updateKey}>
        { formData.mainImgPath ? <Image width={100} src={formData.mainImgPath} /> : null }
      </div>
    );
  }

  const onImageClick = (type: any) => {
    if(type === 'mainImage' && formData.mainImgPath) {
      setFormData(Object.assign(formData, {mainImgPath: '', mainImg: ''}));
    } else if(type === 'tecImage' && formData.tecImgPath) {
      setFormData(Object.assign(formData, {tecImgPath: '', tecImg: ''}));
    } else {
      setImgUpType(type);
      setFileVisible(true);
      setView("grid");
    }

    setUpdateKey(new Date().getTime());
  }

  const tecImageView = () => {
    return (
      <div key={updateKey}>
        { formData.tecImgPath ? <Image width={100} src={formData.tecImgPath} /> : null }
      </div>
    );
  }

  const onContentClear = () => {
    // 清除内容
    let contentLang = formData.contentLang;

    contentLang.forEach((item: { value: string; }) => {
      item.value = "";
    })

    setFormData(Object.assign(formData, {contentLang}));
    cnEditorRef.current && cnEditorRef.current.clearHtml();
    enEditorRef.current && enEditorRef.current.clearHtml();
    ruEditorRef.current && ruEditorRef.current.clearHtml();
  }

  const onSendValue = (val: any, index: number) => {
    // 设置内容
    let contentLang = formData.contentLang;
    contentLang[index].value = val;
    setFormData(Object.assign(formData, {contentLang}));
  }

  const onEditorUp = (insertFn: Function, type: any) => {
    setImgUpType('editor');
    setFileVisible(true);
    setView("grid");

    setEditorInsert({
      insertFn
    });
  }

  const closeFileVisible = () => {
    setFileVisible(false);
  }

  const onImageSelected = (item: any) => {
    if(imgUpType === 'mainImage') {
      setFormData(Object.assign(formData, {mainImg: item.id, mainImgPath: item.path}));
    } else if(imgUpType === 'tecImage') {
      setFormData(Object.assign(formData, {tecImg: item.id, tecImgPath: item.path}));
    } else if(imgUpType === 'editor') {
      editorInsert.insertFn(item.path);
    }

    setFileVisible(false);
  }

  const onSubmit = async () => {
    try {
      await form.validateFields();

      setLoading(true);

      let params = {
        catID: formData.catID.toString().split(','),
        supID: form.getFieldValue('supID'),
        spu: form.getFieldValue('spu'),
        seriesNumber: form.getFieldValue('seriesNumber'),
        mainImg: formData.mainImg,
        tecImg: formData.tecImg,
        orderIndex: form.getFieldValue('orderIndex'),
        nameLang: form.getFieldValue('nameLang'),
        contentLang: formData.contentLang
      };

      const res = await spuServices.addSpuInfo(params);

      const {
        status
      } = res;
      
      if (status === 200) {
        hideIsAddModalVisible();
      }

      setLoading(false);
    } catch (error) {
      message.error("Error");

      setLoading(false);
    }
  }

  const [onPageChange, loading] = useLoading(getSpuList);

  useEffect(() => {
    handleFormChange();
    onPageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, pageSize, JSON.stringify(selected)]);

  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      key: "name",
      title: t("spuList.columns.name"),
      dataIndex: "name",
      ellipsis: true,
      width: 200,
    },
    {
      key: "spu",
      title: "SPU",
      dataIndex: "spu",
      ellipsis: true,
      width: 200,
    },
    {
      key: "series_number",
      title: t("spuList.columns.seriesNumber"),
      dataIndex: "series_number",
      ellipsis: true,
      width: 100,
    },
    {
      key: "hasPoints",
      title: t("spuList.columns.hasPoints"),
      dataIndex: "hasPoints",
      width: 100,
      align: "center" as const,
      render: (value: any) =>
        value ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#bfbfbf" />
        ),
    },
    {
      key: "action",
      title: t("spuList.columns.action"),
      dataIndex: "action",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu onClick={(param: any) => handleMenuClick(param, record)}>
            <Menu.Item key="chart">
              {t("spuList.action.chart")}
            </Menu.Item>
            {
              isAdmin ? (
                <>
                  <Menu.Item key="edit">
                    {t("spuList.action.edit")}
                  </Menu.Item>
                  <Menu.Item key="delete">
                    {t("spuList.action.delete")}
                  </Menu.Item>
                  <Menu.Item key="attr">
                    {t("spuList.action.attr")}
                  </Menu.Item>
                </>
              ) : null
            }
            {/* <Menu.Item key="view">{t("spuList.action.view")}</Menu.Item> */}
            {
              // isAdmin && (
              //   <>
              //     <Menu.Item key="edit">{t("spuList.action.edit")}</Menu.Item>
              //     {/* <Menu.Item key="delete">{t("spuList.action.delete")}</Menu.Item> */}
              //   </>
              // )
            }
          </Menu>
        );

        return (
          <Space>
            <Dropdown overlay={menu}>
              <Button type="text" icon={<UnorderedListOutlined />}>
                <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  let extra: any = null;

  if (isAdmin) {
    extra = (
      <div className="spu-list-page-header-extra">
        <Button 
          key="add"
          type="primary"
          onClick={() => handleOpen()}
          style={{margin: '0 16px'}}
        >
          {t("speedChart.speed.add")}
        </Button>
        <ExportDropdown
          key="export"
          query={query}
          selected={selected}
        />
        <UploadDropdown key="upload" />
      </div>
    );
  }

  return (
    <BaseLayout title={t("route.spuList")}>
      <PageHeader
        title={t("spuList.title")}
        style={{ padding: "16px 0" }}
        extra={extra}
      />
      <Card bodyStyle={{ paddingBottom: 0 }}>
        <Form layout="inline" onFinish={handleSubmit} form={searchRef}>
          <Form.Item
            // label={t("spuList.search.name.label")}
            name="query"
            style={{ paddingBottom: "24px" }}
          >
            <Input placeholder={t("spuList.search.name.placeholder")} />
          </Form.Item>
          <Form.Item style={{ paddingBottom: "24px" }}>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                {t("spuList.search.submit")}
              </Button>
              <Button type="default" onClick={handleReset}>
                {t("spuList.search.reset")}
              </Button>
              <Button type="link" onClick={() => setExpanded(!expanded)}>
                {expanded ? (
                  <>
                    {t("spuSelection.collapse")} <UpOutlined />
                  </>
                ) : (
                  <>
                    {t("spuSelection.expand")} <DownOutlined />
                  </>
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Form
          layout="vertical"
          style={{ display: expanded ? "block" : "none" }}
          form={selectorRef}
          initialValues={{ category: { selected: [] } }}
        >
          <Form.Item name="category" label={t("spuSelection.category")}>
            <CategorySelector />
          </Form.Item>
        </Form>
      </Card>
      <Card style={{ marginTop: 24 }}>
        <ConfigProvider locale={langMap(i18n.language)}>
          <Table
            columns={columns}
            bordered={false}
            dataSource={spuListDataSource}
            scroll={{ x: 325 }}
            pagination={{
              current: page,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
            loading={loading}
          />
        </ConfigProvider>
      </Card>
      <Drawer
        title={t("spuList.add.drawer.title")}
        open={isAddModalVisible}
        placement="right"
        onClose={hideIsAddModalVisible}
        destroyOnClose
        width={700}
        extra={
          <Button 
            type="primary" 
            onClick={onSubmit}
          >
            {t("confirm.ok")}
          </Button>
        }
      >
        <Spin spinning={loadings} >
          <div className='edit-spu-info'>
            <Form
              key={infoId}
              name="basic"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 20 }}
              autoComplete="off"
              form={form}
              initialValues={formData}
              colon={false}
            >
              <Form.Item 
                name="catID" 
                label={t("spuEdit.form.cat")}
                rules={[{ required: true, message: t("spuEdit.form.placeholder.enter") }]}
              >
                <AikonCascader 
                  fieldNames={{ label: 'name', value: 'id', children: 'children' }} 
                  data={classifyList} 
                  className="spu-names-input"
                  value={formData.catID} 
                  radioChange={onCascaderChange}
                >
                </AikonCascader>
              </Form.Item>
              <Form.Item 
                name="seriesNumber" 
                label={t("spuEdit.form.seriesNumber")}
              >
                <Input className="spu-names-input spu-input-width" />
              </Form.Item>
              <Form.Item label={t("spuEdit.form.names")}>
                <>
                  <div className="spu-names">
                    <div 
                      className="spu-clear" 
                      style={{"marginBottom":"16px"}}
                    >
                      <Button 
                        type="primary" 
                        size="small" 
                        onClick={onNameClear}
                      >
                        <CloseOutlined />
                      </Button>
                    </div>
                    <Form.List name="nameLang">
                      {fields =>
                        fields.map(({key, name}) => (
                          <Form.Item 
                            name={[name, "value"]} 
                            label={langList[key]} 
                            labelCol={{ span: 5 }} 
                            key={key} 
                            rules={[{ required: true, message: t("spuEdit.form.placeholder.enter") }]}
                          >
                            <Input className="spu-names-input" />
                          </Form.Item>
                        ))
                      }
                    </Form.List>
                  </div>
                </>
              </Form.Item>
              <Form.Item 
                name="supID" 
                label={t("spuEdit.form.supplier")}
                rules={[{ required: true, message: t("spuEdit.form.placeholder.enter") }]}
              >
                <Select
                  mode="multiple"
                  placeholder={t("spuEdit.form.placeholder.select")}
                  className="spu-names-input spu-input-width"
                  options={supplierList}
                />
              </Form.Item>
              <Form.Item 
                name="spu" 
                label={t("spuEdit.form.spucode")}
                rules={[{ required: true, message: t("spuEdit.form.placeholder.enter") }]}
              >
                <Input className="spu-names-input spu-input-width" />
              </Form.Item>
              <Form.Item 
                name="mainImg" 
                label={t("spuEdit.form.mainImg")}
              >
                <>
                  { mainImageView() }
                  <Button
                    style={{marginTop: formData.mainImgPath?"16px":"0px"}}
                    type="primary"
                    size="small"
                    danger={!!formData.mainImgPath}
                    className="spu-names-input" 
                    onClick={() => onImageClick('mainImage')}
                  >
                    {formData.mainImgPath?t("spuEdit.form.del"):t("spuEdit.form.select")}
                  </Button>
                </>
              </Form.Item>
              <Form.Item 
                name="tecImg" 
                label={t("spuEdit.form.tecImg")}
              >
                <>
                  { tecImageView() }
                  <Button
                    style={{marginTop: formData.tecImgPath?"16px":"0px"}}
                    type="primary"
                    size="small"
                    danger={!!formData.tecImgPath}
                    className="spu-names-input" 
                    onClick={() => onImageClick('tecImage')}
                  >
                    {formData.tecImgPath?t("spuEdit.form.del"):t("spuEdit.form.select")}
                  </Button>
                </>
              </Form.Item>
              <Form.Item 
                name="orderIndex" 
                label={t("spuEdit.form.sort")}
              >
                <InputNumber min={1} className="spu-names-input spu-input-width" />
              </Form.Item>
              <Form.Item label={t("spuEdit.form.details")}>
                <div className="spu-descs">
                  <div 
                    className="spu-clear" 
                    style={{"margin":"14px 14px 0"}}
                  >
                    <Button 
                      type="primary" 
                      size="small" 
                      onClick={onContentClear}
                    >
                      <CloseOutlined />
                    </Button>
                  </div>
                  <h3>中文：</h3>
                  <ClassifyEditor 
                    ref={cnEditorRef} 
                    value={formData.contentLang[0].value} 
                    sendValue={(val: any) => onSendValue(val, 0)} 
                    upImageClick={onEditorUp} 
                  />
                  <h3>
                    English：
                  </h3>
                  <ClassifyEditor 
                    ref={enEditorRef} 
                    value={formData.contentLang[1].value} 
                    sendValue={(val: any) => onSendValue(val, 1)} 
                    upImageClick={onEditorUp} 
                  />
                  <h3>
                    русский：
                  </h3>
                  <ClassifyEditor 
                    ref={ruEditorRef} 
                    value={formData.contentLang[2].value} sendValue={(val: any) => onSendValue(val, 2)} 
                    upImageClick={onEditorUp} 
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </Spin>
        

        <Modal 
          centered width="1080px" 
          open={fileVisible} 
          footer={null} 
          onCancel={closeFileVisible}
        >
          <div style={{maxHeight: "700px", overflowY: "auto"}}>
            <FileCard 
              isSelect={true} 
              imageSelected={onImageSelected} 
            />
          </div>
        </Modal>
      </Drawer>

      <EditSpuInfo ref={editSpuRef} editSuccess={() => onEditSuccess()} />
    </BaseLayout>
  );
};

export default SpuList;

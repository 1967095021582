import { useEffect, useState } from "react";
import { 
  PageHeader, 
  Button,
  Card,
  Table,
  message,
  Menu,
  Dropdown,
  Drawer,
  Form,
  Input,
  Modal,
  InputNumber,
  Radio,
  Spin
} from 'antd';
import { useSearchParams } from "react-router-dom";
import { 
  UnorderedListOutlined, 
  DownOutlined,
  ExclamationCircleOutlined 
} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";
import useLoading from "../../hooks/useLoading";

import spuServices from "../../services/spu";

const { TextArea } = Input;

export default function CustomerList() {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  let page: number = Number(searchParams.get("page")) || 1;
  let pageSize: number = Number(searchParams.get("pageSize")) || 20;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);

  // 弹窗
  const [infoModalVisible, setInfoModalVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [id, setID] = useState('')
  const [name, setName] = useState('')
  const [account, setAccount] = useState('')
  const [email, setEmail] = useState('')
  const [groupID, setGroupID] = useState('')
  const [groupName, setGroupName] = useState('')
  const [companyAddr, setCompanyAddr] = useState('')
  const [userPriceNum, setUserPriceNum] = useState('')
  const [desc, setDesc] = useState('')
  const [group, setGroup] = useState<any[]>([])
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [typeModal, setTypeModal] = useState('add')

  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
    },
    {
      key: "name",
      title: t("spuInfo.pdfExport.company.name"),
      dataIndex: "name",
    },
    {
      key: "account",
      title: t("crm.form.loginName"),
      dataIndex: "account",
    },
    {
      key: "createTime",
      title: t("crm.form.creationDate"),
      dataIndex: "createTime",
    },
    {
      key: "action",
      title: t("skuList.columns.action"),
      dataIndex: "action",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu onClick={(param:any) => hanleMenuClick(param, record)}>
            <Menu.Item key="views">{t("spuChart.tools.view")}</Menu.Item>
            <Menu.Item key="edit">{t("modelList.action.edit")}</Menu.Item>
            <Menu.Item key="delete">{t("modelList.action.delete")}</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button type="text" icon={<UnorderedListOutlined />}>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      }
    },
  ]

  useEffect(() => {
    onPageChange()
  }, [page, pageSize])

  // 列表
  const getData = async () => {
    try {
      const result = await spuServices.getUsers({
        page: page,
        pageSize: pageSize,
      })

      const { response } = result.data

      if (response) {
        const { total, list } = response;
        
        setTotal(total);
        setDataSource(
          list.map((item: any) => ({
            id: item.id,
            name: item.name,
            account: item.account,
            createTime: item.createTime
          }))
        )
      }
      
    } catch (error) {
      message.error("Error")
    }
  }

  const [onPageChange, loading] = useLoading(getData);

  // 详情
  const getInfo = async (id?: number) => {
    try {
      setModalLoading(true)

      const result = await spuServices.getUsersInfo(id ? id : '')

      const { response } = result.data

      if (response) {
        const { group } = response;

        setID(response.id)
        setName(response.name)
        setAccount(response.account)
        setEmail(response.email)
        setGroupID(response.groupID)
        setGroupName(response.groupName)
        setCompanyAddr(response.companyAddr)
        setUserPriceNum(response.userPriceNum)
        setDesc(response.desc)
        setGroup(group)
      }

      setModalLoading(false)
    } catch (error) {
      message.error("Error")
      setModalLoading(false)
    }
  }

  // 分页
  const handlePageChange = (page: number, pageSize?: number) => {
    setSearchParams({
      page: String(page),
      pageSize: pageSize ? String(pageSize) : "20",
    });
  }

  const handleSearch = () => {
    setSearchParams({
      page: '1'
    })

    getData()
  }

  const hideInfoModalVisible = () => {
    setInfoModalVisible(false);
  }

  const hideIsModalVisible = () => {
    setIsModalVisible(false)

    getData()
  }

  const hanleMenuClick = (param: any, record: any) => {
    const {
      key
    } = param

    const {
      id: rId
    } = record

    if (key === 'views') {
      setInfoModalVisible(true)

      getInfo(rId) 
    }

    if (key === 'edit') {
      setIsModalVisible(true)

      setTypeModal('edit')

      setPassword('')
      setPasswordConfirmation('')

      getInfo(rId)
    }

    if (key === 'delete') {
      Modal.confirm({
        title: t("system.message.warningDelete"),
        icon: <ExclamationCircleOutlined />,
        content: true,
        async onOk() {
          return new Promise<void>(async (resolve, reject) => {
            try {
              let deleteId = rId

              const result = await spuServices.deleteUsers(deleteId.toString())

              const { error } = result.data
  
              if (error) {
                message.error(error.message)
  
                resolve()
              } else {
                message.success(t("supplierList.delete.success"))
  
                handleSearch()
                reject()
              }
            } catch (error) {
              reject()
            }
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }

  const onHandleAdd = () => {
    setIsModalVisible(true)

    setTypeModal('add')

    setName('')
    setAccount('')
    setEmail('')
    setPassword('')
    setPasswordConfirmation('')
    setCompanyAddr('')
    setUserPriceNum('')
    setDesc('')

    getInfo()
  }

  const handleInputChange = (type: string, e: any) => {
    if (type === 'name') {
      setName(e.target.value)
    } else if (type === 'account') {
      setAccount(e.target.value)
    } else if (type === 'email') {
      setEmail(e.target.value)
    } else if (type === 'password') {
      setPassword(e.target.value)
    } else if (type === 'passwordConfirmation') {
      setPasswordConfirmation(e.target.value)
    } else if (type === 'companyAddr') {
      setCompanyAddr(e.target.value)
    } else if (type === 'userPriceNum') {
      setUserPriceNum(e)
    } else if (type === 'desc') {
      setDesc(e.target.value)
    } else {
      setGroupID(e.target.value)
    }
  }

  const onSubmit = async () => {
    try {
      let params = {
        id: id,
        name: name,
        account: account,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
        companyAddr: companyAddr,
        userPriceNum: userPriceNum,
        desc: desc,
        groupID: groupID
      }

      if (!name) {
        message.warning(t("crm.message.warningName"))

        return
      }

      if (!account) {
        message.warning(t("crm.message.warningAccount"))

        return
      }

      if (!password && typeModal === 'add') {
        message.warning(t("crm.message.warningPassword"))

        return
      }

      if (!passwordConfirmation && typeModal === 'add') {
        message.warning(t("crm.message.warningPasswordConfirmation"))

        return
      }

      if (password !== passwordConfirmation && typeModal === 'add') {
        message.warning(t("crm.message.warningPasswordVerify"))

        return
      }

      if (!userPriceNum) {
        message.warning(t("crm.message.warningUserPriceNum"))

        return
      }

      // if (!groupID) {
      //   message.warning(t("crm.message.warningUserGroupID"))

      //   return
      // }

      if (typeModal === 'add') {
        const result = await spuServices.addUsers(params)

        const { error } = result.data

        if (error) {
          message.error(error.message)
        } else {
          message.success(t("crm.message.successAdd"))
        }
      } else {
        const result = await spuServices.updateUsers(params)

        const { error } = result.data

        if (error) {
          message.error(error.message)
        } else {
          message.success(t("crm.message.successEdit"))
        }
      }

      hideIsModalVisible()
    } catch (error) {
      message.error("Error")
    }
  }
  
  return (
    <BaseLayout title={t("crm.customerList")}>
      <PageHeader
        title={t("crm.customerList")}
        style={{ padding: "16px 0" }}
        extra={[
          <Button key="new" type="primary" onClick={() => onHandleAdd()}>
            {t("skuList.action.add")}
          </Button>,
        ]}
      />

      <Card style={{ marginTop: 24 }}>
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 325 }}
          pagination={{
            current: page,
            pageSize,
            total,
            onChange: handlePageChange,
          }}
          loading={loading}
        >
        </Table>
      </Card>

      <Drawer
        title={t("spuSelection.details")}
        width={500}
        destroyOnClose
        placement="right"
        open={infoModalVisible}
        onClose={hideInfoModalVisible}
      >
        {
          modalLoading ? (
            <Spin />
          ) : (
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item label={t("spuInfo.pdfExport.company.name")} style={{ color: "#4290f7" }}>
                {name}
              </Form.Item>
              <Form.Item label={t("crm.form.loginName")} style={{ color: "#4290f7" }}>
                {account}
              </Form.Item>
              <Form.Item label={t("crm.form.mail")} style={{ color: "#4290f7" }}>
                {email}
              </Form.Item>
              <Form.Item label={t("crm.form.regionalName")} style={{ color: "#4290f7" }}>
                {groupName}
              </Form.Item>
              <Form.Item label={t("crm.form.companyAddress")} style={{ color: "#4290f7" }}>
                {companyAddr}
              </Form.Item>
              <Form.Item label={t("crm.form.priceCoefficient")} style={{ color: "#4290f7" }}>
                {userPriceNum}
              </Form.Item>
              <Form.Item label={t("crm.form.companyProfile")} style={{ color: "#4290f7" }}>
                {desc}
              </Form.Item>
            </Form>
          )
        }
      </Drawer>

      <Drawer
        title={typeModal === 'add' ? t("speedChart.speed.add") : t("spuChart.tools.edit")}
        width={500}
        destroyOnClose
        placement="right"
        open={isModalVisible}
        onClose={hideIsModalVisible}
        extra={
          <Button 
            type="primary" 
            onClick={onSubmit}
          >
            {t("spuChart.Modify.submit")}
          </Button>
        }
      >
        {
          modalLoading && typeModal === 'edit' ? (
            <Spin />
          ) : (
            <Form
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item 
                label={t("spuInfo.pdfExport.company.name")}
                required={true}
              >
                <Input 
                  style={{ width: 300 }} 
                  value={name}
                  onChange={(e) => handleInputChange('name', e)}
                />
              </Form.Item>
              <Form.Item 
                label={t("crm.form.loginName")}
                required={true}
              >
                <Input 
                  style={{ width: 300 }}
                  value={account}
                  onChange={(e) => handleInputChange('account', e)}
                />
              </Form.Item>
              <Form.Item label={t("crm.form.mail")}>
                <Input 
                  style={{ width: 300 }}
                  value={email}
                  onChange={(e) => handleInputChange('email', e)}
                />
              </Form.Item>
              <Form.Item 
                label={t("login.password.placeholder")}
                required={typeModal === 'add'}
              >
                <Input.Password 
                  style={{ width: 300 }}
                  value={password}
                  onChange={(e) => handleInputChange('password', e)}
                />
              </Form.Item>
              <Form.Item 
                label={t("crm.form.passwordConfirmation")}
                required={typeModal === 'add'}
              >
                <Input.Password 
                  style={{ width: 300 }}
                  value={passwordConfirmation}
                  onChange={(e) => handleInputChange('passwordConfirmation', e)}
                />
              </Form.Item>
              <Form.Item label={t("crm.form.companyAddress")}>
                <Input 
                  style={{ width: 300 }}
                  value={companyAddr}
                  onChange={(e) => handleInputChange('companyAddr', e)}
                />
              </Form.Item>
              <Form.Item 
                label={t("crm.form.priceCoefficient")}
                required={true}
              >
                <InputNumber
                  style={{ width: 300 }}
                  value={userPriceNum}
                  step="0.01"
                  precision={2}
                  onChange={(e) => handleInputChange('userPriceNum', e)}
                />
              </Form.Item>
              <Form.Item label={t("crm.form.companyProfile")}>
                <TextArea  
                  style={{ width: 300 }}
                  value={desc}
                  rows={4}
                  onChange={(e) => handleInputChange('desc', e)}
                />
              </Form.Item>
              <Form.Item 
                label={t("crm.form.region")}
                // required={true}
              >
                <Radio.Group 
                  style={{ width: 300 }}
                  value={groupID}
                  onChange={(e) => handleInputChange('groupID', e)}
                >
                  {
                    group.map((v, i) => {
                      const {
                        id,
                        name
                      } = v

                      return (
                        <Radio 
                          key={i}
                          value={id}
                        >
                          {name}
                        </Radio>
                      )
                    })
                  }
                </Radio.Group>
              </Form.Item>
            </Form>
          )
        }
      </Drawer>
    </BaseLayout>
  )
}
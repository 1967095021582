import React, { useState } from "react";
import { Card, Form, Input, Button, Checkbox, Dropdown, Menu, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined, DownOutlined } from "@ant-design/icons";
import Brand from "./Brand";
import "./LoginDialog.css";
import { useTranslation } from "react-i18next";
import { languages } from "../i18n";
import spuServices from "../services/spu";

interface LoginDialogProps {
  onLogin: (values: object) => void;
  loading: boolean;
}

const LoginDialog = ({ onLogin, loading }: LoginDialogProps) => {
  const { t, i18n } = useTranslation();
  const [type, setType] = useState('login')
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [mobile,  setMobile] = useState('')
  const [reLoading, setReLoading] = useState(false)

  const handleLanguageChange = (key: string) => {
    // 语言菜单选择
    const langs = languages.map((lang) => lang.key);
    if (langs.includes(key)) {
      i18n.changeLanguage(key);
    }
  };

  const handleJumpTo = () => {
    setType('register')
    setName('')
    setCode('')
    setAccount('')
    setPassword('')
    setPasswordConfirmation('')
    setMobile('')
  }

  const handleReturn = () => {
    setType('login')
  }

  const handleRegister = async () => {
    try {
      let params = {
        name: name,
        account: account,
        code: code,
        password: password,
        passwordConfirmation: passwordConfirmation,
        mobile: mobile
      }

      if (!name) {
        message.warning(t("loginDialog.message.name"))

        return
      }

      if (!code) {
        message.warning(t("loginDialog.message.code"))

        return
      }

      if (!account) {
        message.warning(t("loginDialog.message.account"))

        return
      }

      if (!password) {
        message.warning(t("crm.message.warningPassword"))

        return
      }

      if (!passwordConfirmation) {
        message.warning(t("crm.message.warningPasswordConfirmation"))

        return
      }

      if (password !== passwordConfirmation) {
        message.warning(t("crm.message.warningPasswordVerify"))

        return
      }

      if (!mobile) {
        message.warning(t("loginDialog.message.mobile"))

        return
      }

      setReLoading(true)

      const result = await spuServices.adminUsersRegister(params)

      const { error } = result.data

      if (error) {
        message.error(error.message)
      } else {
        message.success(t("loginDialog.message.registeredSuccessfully"))
        setType('login')
      }

      setReLoading(false)
    } catch (error) {
      setReLoading(false)
      message.error("Error")
    }
  }

  const handleInputChange = (type: string, e: any) => {
    if (type === 'name') {
      setName(e.target.value)
    } else if (type === 'code') {
      setCode(e.target.value)
    } else if (type === 'account') {
      setAccount(e.target.value)
    } else if (type === 'password') {
      setPassword(e.target.value)
    } else if (type === 'passwordConfirmation') {
      setPasswordConfirmation(e.target.value)
    } else {
      setMobile(e.target.value)
    }
  }

  const menu = (
    <Menu
      onClick={({ key }) => handleLanguageChange(key)}
      selectedKeys={[i18n.language]}
    >
      {languages.map((lang) => (
        <Menu.Item key={lang.key}>{lang.name}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Card id="login-dialog">
      <div className="login-dialog-language">
        <Dropdown overlay={menu}>
          <Button type="link">
            {t("chart.language")} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <Brand logoSize="44px" fontSize="32px" lineHeight="32px" />
      <div className="login-title">
        {type === 'login' ? t("loginDialog.title.loginAccount") : t("loginDialog.title.registerAccount")}
      </div>
      {
        type === 'login' ? (
          <Form id="login-dialog-form" size="large" onFinish={onLogin}>
            <Form.Item
              name="account"
              rules={[{ required: true, message: t("login.account.error") }]}
            >
              <Input
                prefix={<UserOutlined className="login-input-icon" />}
                placeholder={t("login.account.placeholder")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t("login.password.error") }]}
            >
              <Input
                prefix={<LockOutlined className="login-input-icon" />}
                type="password"
                placeholder={t("login.password.placeholder")}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t("login.remember")}</Checkbox>
              </Form.Item>
              <Button type="link" size="small" className="login-forget-password">
                {t("login.forgot")}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                {t("login.submit")}
              </Button>
              <Button
                style={{ width: "100%", marginTop: '16px' }}
                onClick={handleJumpTo}
              >
                {t("loginDialog.register")}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Form
            id="login-dialog-form"
            name="register"
          >
            <Form.Item>
              <Input
                placeholder={t("loginDialog.message.name")}
                value={name}
                onChange={(e) => handleInputChange('name', e)}
              />
            </Form.Item>
            <Form.Item>
              <Input
                placeholder={t("loginDialog.message.code")}
                value={code}
                onChange={(e) => handleInputChange('code', e)}
              />
            </Form.Item>
            <Form.Item>
              <Input
                placeholder={t("loginDialog.message.account")}
                value={account}
                onChange={(e) => handleInputChange('account', e)}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password 
                type="password"
                placeholder={t("crm.message.warningPassword")}
                value={password}
                onChange={(e) => handleInputChange('password', e)}
              />
            </Form.Item>
            <Form.Item>
              <Input.Password 
                type="password"
                placeholder={t("crm.message.warningPasswordConfirmation")}
                value={passwordConfirmation}
                onChange={(e) => handleInputChange('passwordConfirmation', e)}
              />
            </Form.Item>
            <Form.Item>
              <Input 
                placeholder={t("loginDialog.message.mobile")}
                value={mobile}
                onChange={(e) => handleInputChange('mobile', e)}
              />
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col span={8}>
                  <Button
                    style={{ width: "100%" }}
                    onClick={handleReturn}
                  >
                    {t("loginDialog.return")}
                  </Button>
                </Col>
                <Col span={16}>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    loading={reLoading}
                    onClick={handleRegister}
                  >
                    {t("loginDialog.register")}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )
      }
    </Card>
  );
};

export default LoginDialog;

import { useTranslation } from "react-i18next";

import { Card, Col, Row } from "antd";
import { precision } from "../../../utils";

import './PerformanceResult.css';

const PerformanceResult = ({
  fieldsResult
}: any) => {
  const { t } = useTranslation();

  if (!fieldsResult) {
    return <></>;
  }

  const {
    diameter,
    speed,
    frequency,
    power,
    npsh,
    eff
  } = fieldsResult;

  return (
    <Card className='per-formance-result'>
      <Row gutter={18}>
        <Col span={12}>
          <div className='chart-title'>
            {t("chart.diameter")}
          </div>
          <div className='chart-label'>
            {precision(diameter, 0)}mm
          </div>
          {/* <Statistic
            title={t("chart.diameter")}
            value={diameter}
            precision={0}
            suffix="mm"
          /> */}
        </Col>
        <Col span={12}>
          <div className='chart-title'>
            {t("chart.speed")}
          </div>
          <div className='chart-label'>
            {precision(speed, 0)}rpm
          </div>
          {/* <Statistic
            title={t("chart.speed")}
            value={speed}
            precision={0}
            suffix="rpm"
          /> */}
        </Col>
        <Col span={12}>
          <div className='chart-title'>
            {t("chart.frequency")}
          </div>
          <div className='chart-label'>
            {precision(frequency, 2)}hz
          </div>
          {/* <Statistic
            title={t("chart.frequency")}
            value={frequency}
            precision={2}
            suffix="hz"
          /> */}
        </Col>
        <Col span={12}>
          <div className='chart-title'>
            P
          </div>
          <div className='chart-label'>
            {precision(power, 2)}kw
          </div>
        </Col>
        <Col span={12}>
          <div className='chart-title'>
            NPSHr
          </div>
          <div className='chart-label'>
            {precision(npsh, 2)}m
          </div>
          {/* <Statistic title="NPSHr" value={npsh} precision={2} suffix="m" /> */}
        </Col>
        <Col span={12}>
          <div className='chart-title'>
            {t("chart.efficiency")}
          </div>
          <div className='chart-label'>
            {precision(eff, 2)}%
          </div>
          {/* <Statistic
            title={t("chart.efficiency")}
            value={eff}
            precision={2}
            suffix="%"
          /> */}
        </Col>
      </Row>
    </Card>
  );
};

export default PerformanceResult;
